import i18n, { InitOptions } from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationPt from './locales/pt-BR/translation.json';

const language = navigator.language;

// Initialize i18next
const resources = {
    en: {
        translation: translationEn,
    },
    'pt-BR': {
        translation: translationPt,
    },
};

const i18nConfig: InitOptions = {
    resources,
    lng: language, // Default language
    fallbackLng: 'en', // Fallback language
    interpolation: {
        escapeValue: false, // React already escapes values
    },
};

i18n.use(initReactI18next) // Bind with React
    .init(i18nConfig);

export default i18n;
