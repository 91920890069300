import { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { useAppDispatch } from '../../../../../Redux/store';
import { setSelectedGraphData, setSelectedMetricGraphData } from '../../../../../Redux/TrendMetric';

interface TrendsFilterProps {
    timeFilter: string;
    setTimeFilter: Dispatch<SetStateAction<string>>;
    setBackTimeFilter: Dispatch<SetStateAction<string[]>>;
}

//time filter for trends
const TrendsFilter: React.FC<TrendsFilterProps> = ({ setTimeFilter, timeFilter, setBackTimeFilter }) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    return (
        <div className='filter_box flex items-center '>
            <div
                className={`${timeFilter === 'Day' ? 'active' : ''} relative filter_btn`}
                onClick={() => {
                    setTimeFilter('Day');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.d')}
                <div className='tooltip_box'>{t('today')}</div>
            </div>
            <div
                className={`${timeFilter === 'Week' ? 'active' : ''} relative filter_btn`}
                onClick={() => {
                    setTimeFilter('Week');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.w')}
                <div className='tooltip_box'>{t('last_7_days')}</div>
            </div>
            <div
                className={`${timeFilter === 'Month' ? 'active' : ''} relative filter_btn`}
                onClick={() => {
                    setTimeFilter('Month');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.m')}
                <div className='tooltip_box'>{t('last_30_days')}</div>
            </div>
            <div
                className={`${timeFilter === 'Year' ? 'active' : ''} relative filter_btn`}
                onClick={() => {
                    setTimeFilter('Year');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.y')}
                <div className='tooltip_box'>{t('last_year')}</div>
            </div>
            <div
                className={`${timeFilter === 'All' ? 'active' : ''} relative filter_btn`}
                onClick={() => {
                    setTimeFilter('All');
                    dispatch(
                        setSelectedGraphData({
                            value: null,
                            drilledDate: '',
                        }),
                    );
                    dispatch(
                        setSelectedMetricGraphData({
                            metricValue: null,
                            metricDrilledDate: null,
                        }),
                    );
                    setBackTimeFilter([]);
                }}
            >
                {t('trendFilter.a')}
                <div className='tooltip_box'> {t('allTime')}</div>
            </div>
        </div>
    );
};

export default TrendsFilter;
