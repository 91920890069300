import { ReactComponent as CheckIcon } from '../../../assets/icons/icon-check-filled.svg';
import { TierFeature } from './TierTable';

export const organisationTier: TierFeature[] = [
    {
        title: 'subscription_tier.EVA_AI.TITLE',
        tooltip: 'subscription_tier.EVA_AI.TOOLTIP',
        children: [
            {
                title: 'subscription_tier.EVA_AI.INTERACTIVE_UPDATES.TITLE',
                tooltip: 'subscription_tier.EVA_AI.INTERACTIVE_UPDATES.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.EVA_AI.GEN_BLUEPRINT.TITLE',
                tooltip: 'subscription_tier.EVA_AI.GEN_BLUEPRINT.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
        ],
    },
    {
        title: 'subscription_tier.BLUEPRINTS.TITLE',
        tooltip: 'subscription_tier.BLUEPRINTS.TOOLTIP',
        children: [
            {
                title: 'subscription_tier.BLUEPRINTS.PROTOCOLS.TITLE',
                tooltip: 'subscription_tier.BLUEPRINTS.PROTOCOLS.TOOLTIP',
                sheild: '10',
                oversight: '40',
            },
            {
                title: 'subscription_tier.BLUEPRINTS.TEMPLATES.TITLE',
                tooltip: 'subscription_tier.BLUEPRINTS.TEMPLATES.TOOLTIP',
                sheild: '4',
                oversight: 'Full List',
            },
            {
                title: 'subscription_tier.BLUEPRINTS.COLLABORATE.TITLE',
                tooltip: 'subscription_tier.BLUEPRINTS.COLLABORATE.TOOLTIP',
                sheild: '1',
                oversight: 'Unlimited',
            },
            {
                title: 'subscription_tier.BLUEPRINTS.TESTMODE.TITLE',
                tooltip: 'subscription_tier.BLUEPRINTS.TESTMODE.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.BLUEPRINTS.SNAPSHOTS.TITLE',
                tooltip: 'subscription_tier.BLUEPRINTS.SNAPSHOTS.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
        ],
    },
    {
        title: 'subscription_tier.INTERACTION_PATTERNS.TITLE',
        tooltip: 'subscription_tier.INTERACTION_PATTERNS.TOOLTIP',
        children: [
            {
                title: 'subscription_tier.INTERACTION_PATTERNS.CUSTOM_POLICIES.TITLE',
                tooltip: 'subscription_tier.INTERACTION_PATTERNS.CUSTOM_POLICIES.TOOLTIP',
                sheild: '500 Users',
                oversight: '500 Users',
            },
            {
                title: 'subscription_tier.INTERACTION_PATTERNS.NORTH_STAR.TITLE',
                tooltip: 'subscription_tier.INTERACTION_PATTERNS.NORTH_STAR.TOOLTIP',
                sheild: 'Price Per Seat',
                oversight: 'Price Per Seat',
                upcoming: true,
            },
        ],
    },
    {
        title: 'subscription_tier.ANALYTICS.TITLE',
        tooltip: 'subscription_tier.ANALYTICS.TOOLTIP',
        children: [
            {
                title: 'subscription_tier.ANALYTICS.QUICK_VIEW.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.QUICK_VIEW.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                upcoming: true,
            },
            {
                title: 'subscription_tier.ANALYTICS.ENGAGEMENT.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.ENGAGEMENT.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ANALYTICS.TRENDS.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.TRENDS.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ANALYTICS.FLAGS.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.FLAGS.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ANALYTICS.TRIGGERS.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.TRIGGERS.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ANALYTICS.LOGS.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.LOGS.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ANALYTICS.PROTOCOL.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.PROTOCOL.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                upcoming: true,
            },
            {
                title: 'subscription_tier.ANALYTICS.NAVI.TITLE',
                tooltip: 'subscription_tier.ANALYTICS.NAVI.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            // {
            //     title: 'Customizable Alerting and Escalation',
            //     tooltip: 'Go in depth and interact naturally with EVA.',
            //     sheild: null,
            //     oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            // },
        ],
    },
    {
        title: 'subscription_tier.ADMIN.TITLE',
        tooltip: 'subscription_tier.ADMIN.TOOLTIP',
        children: [
            {
                title: 'subscription_tier.ADMIN.GLOBAL.TITLE',
                tooltip: 'subscription_tier.ADMIN.GLOBAL.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ADMIN.MEMBER.TITLE',
                tooltip: 'subscription_tier.ADMIN.MEMBER.TOOLTIP',
                sheild: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ADMIN.TREASURER.TITLE',
                tooltip: 'subscription_tier.ADMIN.TREASURER.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ADMIN.STEWARD.TITLE',
                tooltip: 'subscription_tier.ADMIN.STEWARD.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ADMIN.ARCHITECT.TITLE',
                tooltip: 'subscription_tier.ADMIN.ARCHITECT.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
            {
                title: 'subscription_tier.ADMIN.TREASURER.TITLE',
                tooltip: 'subscription_tier.ADMIN.TREASURER.TOOLTIP',
                sheild: null,
                oversight: <CheckIcon className='w-3 h-3' fill='#0014FF' />,
            },
        ],
    },
];
