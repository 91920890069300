import { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { ReactComponent as CriticalThinking } from '../../../../src/assets/icons/CriticalThinking.svg';
import { ReactComponent as GoldStandard } from '../../../../src/assets/icons/GoldStandard.svg';
import { ReactComponent as GoodPractice } from '../../../../src/assets/icons/GoodPractice.svg';
import { ReactComponent as NorthStarIcon } from '../../../../src/assets/images/north-star.svg';
import { SUBSCRIPTION_TYPE } from '../../../utils/constants';
import { Switch } from '../../basic/Switch.component';
import NorthStarDialog from './NorthStarDialog.component';

const NorthStarAddon = ({ type }: { type: string }) => {
    const { t } = useTranslation();
    const [enableNorthStarAddOn, setEnableNorthStarAddon] = useState(false);
    const [openNorthStarDialog, setOpenNorthStarDialog] = useState(false);

    return (
        <div className='north-star-addon-container'>
            <div className='flex justify-between items-center'>
                <div>
                    <NorthStarIcon className='w-10 h-10' />
                </div>
                <div className='flex space-x-6 items-center'>
                    <div className='text-darker text-opacity-75'>
                        <div className='flex items-center space-x-1'>
                            <p className={'font-semibold text-xl'}>£{type === SUBSCRIPTION_TYPE.YEARLY ? 39 : 49}</p>
                            <span className={'text-xs font-normal'}>/seat/mo</span>
                        </div>
                        <p className='text-xs'>
                            {t('subscription_tier.annual')} {t('subscription_tier.billing')}
                        </p>
                    </div>

                    <div>
                        <Switch checked={enableNorthStarAddOn} onChange={() => setOpenNorthStarDialog(true)} />
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                <h1 className='text-darker text-opacity-75 font-semibold'>{t('nsa.title')}</h1>
                <p className='text-darker text-opacity-50 text-sm text'>{t('nsa.description')}</p>
                <div className='mt-4'>
                    <p className='italic text-sm text-darker text-opacity-75 font-medium'>{t('nsa.discover')}</p>
                    <div className='flex items-center space-x-6 mt-2'>
                        <div className='ns-protocolType flex space-x-2 items-center'>
                            <div className='icon'>
                                <GoldStandard />
                            </div>
                            <p className='text-sm icon-text'>{t('protocolTypes.Gold Standard')}</p>
                        </div>
                        <div className='ns-protocolType flex space-x-2 items-center'>
                            <div className='icon'>
                                <GoodPractice />
                            </div>
                            <p className='text-sm icon-text'>{t('protocolTypes.Good Practice')}</p>
                        </div>
                        <div className='ns-protocolType flex space-x-2 items-center'>
                            <div className='icon'>
                                <CriticalThinking />
                            </div>
                            <p className='text-sm icon-text'>{t('protocolTypes.Critical Thinking')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <NorthStarDialog
                open={openNorthStarDialog}
                setClose={setOpenNorthStarDialog}
                onConfirm={() => setEnableNorthStarAddon(true)}
            />
        </div>
    );
};

export default NorthStarAddon;
