import { ProtocolTypes } from '../../types';

export const FrequencyList = {
    Hourly: 'Hourly',
    Daily: 'Daily',
    Weekly: 'Weekly',
    Monthly: 'Monthly',
    Quarterly: 'Quarterly',
    Yearly: 'Yearly',
};

export const CalculationType = {
    Sum: 'Sum',
    Average: 'Average',
    WeightedAverage: 'Weighted Average',
    Count: 'Count',
    Percentage: 'Percentage',
    Min: 'Min',
    Max: 'Max',
    Mode: 'Mode',
    LinearRegression: 'Linear Regression',
    ExponentialSmoothing: 'Exponential Smoothing',
    MovingAverage: 'Moving Average',
    Last: 'Last',
};

export const FormatList = {
    Percentage: 'Percentage',
    Ratio: 'Ratio',
    Score: 'Score',
    Binary: 'Binary',
    Trend: 'Trend',
    Index: 'Index',
    Rank: 'Rank',
    GrowthRate: 'Growth Rate',
    Velocity: 'Velocity',
    Forecast: 'Forecast',
    RiskLevel: 'Risk Level',
    Number: 'Number',
};

export const getFormatListByCategory = (category: string) => {
    switch (category) {
        case 'metricCategoryType.KRI':
            return [FormatList.Percentage, FormatList.Ratio, FormatList.Score, FormatList.Binary, FormatList.Trend];
        case 'metricCategoryType.OPI':
            return [FormatList.Percentage, FormatList.Ratio, FormatList.Score, FormatList.Index, FormatList.Rank];
        case 'metricCategoryType.LI':
            return [
                FormatList.GrowthRate,
                FormatList.Velocity,
                FormatList.Forecast,
                FormatList.RiskLevel,
                FormatList.Trend,
            ];
        default:
            return [];
    }
};

export const getCalculationMethodList = (format: string) => {
    switch (format) {
        case FormatList.Percentage:
            return [
                CalculationType.Average,
                CalculationType.WeightedAverage,
                CalculationType.Sum,
                CalculationType.Min,
                CalculationType.Max,
            ];
        case FormatList.Ratio:
            return [CalculationType.Average, CalculationType.WeightedAverage, CalculationType.Min, CalculationType.Max];
        case FormatList.Score:
            return [
                CalculationType.Average,
                CalculationType.WeightedAverage,
                CalculationType.Sum,
                CalculationType.Min,
                CalculationType.Max,
            ];
        case FormatList.Binary:
            return [
                CalculationType.Count,
                // CalculationType.Percentage
            ];
        case FormatList.Trend:
            return [
                CalculationType.Mode,
                // CalculationType.Percentage
            ];
        case FormatList.Index:
            return [CalculationType.WeightedAverage, CalculationType.Sum];
        case FormatList.Rank:
            return [CalculationType.Min, CalculationType.Max, CalculationType.Mode];
        case FormatList.GrowthRate:
            return [CalculationType.Average, CalculationType.WeightedAverage, CalculationType.Min, CalculationType.Max];
        case FormatList.Velocity:
            return [CalculationType.Average, CalculationType.WeightedAverage, CalculationType.Min, CalculationType.Max];
        case FormatList.Forecast:
            return [
                CalculationType.LinearRegression,
                CalculationType.ExponentialSmoothing,
                CalculationType.MovingAverage,
            ];
        case FormatList.RiskLevel:
            return [
                CalculationType.Mode,
                // CalculationType.Percentage
            ];
        case FormatList.Number:
            return [
                CalculationType.Sum,
                CalculationType.Average,
                CalculationType.WeightedAverage,
                CalculationType.Min,
                CalculationType.Max,
                CalculationType.Count,
            ];
        default:
            return [];
    }
};

export const getFrequencyList = () => {
    return [
        FrequencyList.Hourly,
        FrequencyList.Daily,
        FrequencyList.Weekly,
        FrequencyList.Monthly,
        FrequencyList.Quarterly,
        FrequencyList.Yearly,
    ];
};

export const getTimeFrameList = (frequency: string) => {
    switch (frequency) {
        case FrequencyList.Hourly:
            return [
                FrequencyList.Daily,
                FrequencyList.Weekly,
                FrequencyList.Monthly,
                FrequencyList.Quarterly,
                FrequencyList.Yearly,
            ];
        case FrequencyList.Daily:
            return [FrequencyList.Weekly, FrequencyList.Monthly, FrequencyList.Quarterly, FrequencyList.Yearly];
        case FrequencyList.Weekly:
            return [FrequencyList.Monthly, FrequencyList.Quarterly, FrequencyList.Yearly];
        case FrequencyList.Monthly:
            return [FrequencyList.Quarterly, FrequencyList.Yearly];
        case FrequencyList.Quarterly:
            return [FrequencyList.Yearly];
        case FrequencyList.Yearly:
            return [];
        default:
            return [];
    }
};

export const getAggregationTypeList = (format: string) => {
    switch (format) {
        case FormatList.Percentage:
            return [CalculationType.Average, CalculationType.Sum, CalculationType.Min, CalculationType.Max];
        case FormatList.Ratio:
            return [CalculationType.Average, CalculationType.Min, CalculationType.Max];
        case FormatList.Score:
            return [CalculationType.Average, CalculationType.Sum, CalculationType.Min, CalculationType.Max];
        case FormatList.Binary:
            return [CalculationType.Count];
        case FormatList.Trend:
            return [
                CalculationType.Mode,
                // CalculationType.Percentage
            ];
        case FormatList.Index:
            return [CalculationType.WeightedAverage, CalculationType.Sum];
        case FormatList.Rank:
            return [CalculationType.Min, CalculationType.Max, CalculationType.Mode];
        case FormatList.GrowthRate:
            return [CalculationType.Average, CalculationType.Min, CalculationType.Max];
        case FormatList.Velocity:
            return [CalculationType.Average, CalculationType.Min, CalculationType.Max];
        case FormatList.Forecast:
            return [
                CalculationType.LinearRegression,
                CalculationType.ExponentialSmoothing,
                CalculationType.MovingAverage,
            ];
        case FormatList.RiskLevel:
            return [
                CalculationType.Mode,
                // CalculationType.Percentage
            ];
        case FormatList.Number:
            return [
                CalculationType.Sum,
                CalculationType.Average,
                CalculationType.Min,
                CalculationType.Max,
                CalculationType.Count,
            ];
        default:
            return [];
    }
};

export enum SortType {
    Date = 'Date',
    AtoZ = 'A-Z',
    ZtoA = 'Z-A',
    Category = 'Category',
}

export enum FeedbackStatus {
    Like = 'Like',
    Dislike = 'Dislike',
}

export type MetricCategoryType = {
    name: string;
    color: string;
    key: string;
    bgColor: string;
    borderColor: string;
};

export const categoryList: MetricCategoryType[] = [
    {
        name: 'metricCategoryType.KRI',
        color: '#00B8FF',
        borderColor: 'rgba(0, 184, 255, 0.25)',
        key: 'KRI',
        bgColor: `rgba(0, 184, 255, 0.03)`,
    },
    {
        name: 'metricCategoryType.OPI',
        color: '#61DC36',
        key: 'OPI',
        borderColor: 'rgba(97, 220, 54, 0.25)',
        bgColor: `rgba(97, 220, 54, 0.03)`,
    },
    {
        name: 'metricCategoryType.LI',
        color: '#FE9549',
        key: 'LI',
        borderColor: 'rgba(255, 112, 67, 0.25)',
        bgColor: `rgba(255, 112, 67, 0.03)`,
    },
];

export const getMetricCategory = (name: string): MetricCategoryType => {
    switch (true) {
        case name === 'Key Result Indicator (KRI)' || name === 'KRI':
            return categoryList[0];
        case name === 'Organisational Performance Indicator (OPI)' || name === 'OPI':
            return categoryList[1];
        case name === 'Leading Indicator (LI)' || name === 'LI':
            return categoryList[2];
        default:
            return categoryList[0];
    }
};

export enum ProtocolRequirementLevel {
    Required = 'Required',
    Recommended = 'Recommended',
    Suggested = 'Suggested',
}

export type MetricProtocolType = {
    protocolName: string;
    protocolType: ProtocolTypes;
    protocolIndex: number;
    requirementLevel: ProtocolRequirementLevel;
    graphData: MetricGraph;
};

export type ContributionType = {
    createdBy: number;
    createdAt: Date;
    updatedAt: Date;
};

export type Feedback = {
    userId: number;
    status: string;
    metricId: string;
    createdAt: Date;
};

export type Metric = {
    id?: string;
    name: string;
    description: string;
    category: string;
    format: string;
    frequency: string;
    method: string;
    timeFrame: string;
    type: string;
    feedback: boolean;
    like?: Feedback[];
    dislike?: Feedback[];
    isFavorite?: boolean;
    isLocked?: boolean;
    isDefault?: boolean;
    isEnabled?: boolean;
    newMetric?: boolean;
    contributors?: ContributionType[];
    createdAt?: Date;
    relatedProtocols?: MetricProtocol[];
    isLoading?: boolean;
};

export type MetricGraph = {
    protocolName: string;
    protocolClass: string;
    strokeColor: string;
    strokeType?: string;
};

export type MetricProtocol = {
    status: 'available' | 'update' | 'create';
    protocolArray: MetricProtocolType[];
};

export interface MetricTypes {
    id: string;
    data: number[];
    name: string;
    description: string;
    category: string;
    format: string;
    feedback: boolean;
    frequency: string;
    method: string;
    timeFrame: string;
    type: string;
    isFavorite: boolean;
    isLocked: boolean;
    protocolNames: Array<{
        name: string;
        protocolType: string;
    }>;
    isEnabled: boolean;
    isDefault: boolean;
    contributors: Array<{
        createdBy: number;
        createdAt: string;
        updatedAt: string | null;
    }>;
    like: string[];
    dislike: string[];
    createdAt: string;
}
