/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from 'react';

import { Box, ClickAwayListener, Dialog, Drawer, makeStyles } from '@material-ui/core';
import { useTour } from '@reactour/tour';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import miniOrbit from '../../../../../../src/assets/icons/mini_OrbitIocn.svg';
import miniOrbitHover from '../../../../../../src/assets/icons/mini_OrbitIocnHover.svg';
import miniOrbitwhite from '../../../../../../src/assets/icons/mini_Orbitwhite.svg';
import { appInsights } from '../../../../../AppInsights';
import { ReactComponent as ArrowIcon } from '../../../../../assets/icons/icon-arrow-left-up.svg';
import fillMetamarkerIcon from '../../../../../assets/icons/icon-fill-metamarker.svg';
import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { useOrganisationForm } from '../../../../../hooks/useOrganisation';
import { useSettings } from '../../../../../hooks/useSettings';
import { SetIsCreateEvaFromBlueprints } from '../../../../../Redux/EvaInfo';
import { RootState } from '../../../../../Redux/store';
import { store } from '../../../../../store';
import { AnimatedCircle } from '../../../../basic/AnimatedCircle';
import { Button } from '../../../../basic/Button.component';
import { CustomLoader } from '../../../../basic/CustomeLoader/CustomLoader';
import ErrorMessage from '../../../../basic/ErrorMessage.component';
import { IconAlert } from '../../../../basic/IconAlert.component';
import { useServices } from '../../../../ServiceProvider';
import {
    EvaCreationMethod,
    EvaType,
    Gradients,
    MetaMarkerData,
    MetaMarkerItem,
    Nuances,
    SubScriptionType,
    ThumbData,
} from '../types';
import { getRgbColorCode } from './ColorCode';
import { CreateEvaOrbit } from './createEva/CreateEvaOrbit';
import { compareMetaMarkers, compareNuanceData, convertToThumbData } from './createEva/EvaCompare';
import { StyledTextField } from './createEva/StyledTextField';
import EvaDialog from './EvaDialog.component';
import EvaHeader from './EvaHeader';
import EvaluateHint from './evaluate/EvaluateHint.component';
import { EvaTemplate } from './evaTemplate';
import EvaTitleDescription from './EvaTitleDescription.component';
import planet from './img/planet-blue.png';
import './style.css';
import ProtocolList from './ProtocolList';
import RightSidePanel from './RightSidePanel.component';
import Sidebar from './Sidebar';
import TourDialog from './TourDialog.component';
import './slider/slider.css';

const useStyles = makeStyles(() => ({
    drawer: {
        width: '23.021vw',
        top: '130px !important',
        left: '30px',
        flexShrink: 0,
        '& .MuiBackdrop-root': {
            backgroundColor: 'transparent',
        },
    },
    drawerPaper: {
        boxSizing: 'border-box',
        width: '23.021vw',
        top: '138px !important',
        left: '32px',
        height: ' -webkit-fill-available',
        '@media (max-width: 991px)': {
            padding: '10px',
        },
        background: 'rgba(111, 191, 225, 0.1) !important',
        borderRadius: '15px',
        boxShadow: 'none',
    },
    evaField: {
        borderRadius: 7,
        backgroundColor: 'transparent',
        width: '100%',
        '& .MuiInput-underline:before, & .MuiInput-underline:after': {
            display: 'none',
        },
        '& .MuiInput-underline input': {
            textAlign: 'center',
        },
        '& .MuiInputBase-input::placeholder': {
            opacity: 1,
        },
        color: 'rgba(255, 255, 255, 0.75)',
    },
    placeHolder: {
        position: 'absolute',
        color: 'rgba(255, 255, 255, 0.75)',
        fontSize: 12,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    headerTitle: {
        wordBreak: 'break-all',
    },
}));

export const evaTitleFieldStyle = {
    fontSize: 16,
    fontWeight: 600,
};

interface CreateEvaModelProps {
    open: boolean;
    handleClose: () => void;
    isPublished: boolean;
    setPublishedEvaExist: (value: boolean) => void;
    existingEvaId?: string;
    isPublishedEvaModel?: boolean;
    isArchived?: boolean;
    archive: boolean;
    setArchive: (value: boolean) => void;
    restarted?: boolean;
    protocolIndex?: number | null;
    setProtocolIndex?: React.Dispatch<React.SetStateAction<number | null>>;
    setArchiveEvaId?: React.Dispatch<React.SetStateAction<string | null>>;
}

const CreateEvaModel: React.FC<CreateEvaModelProps> = ({
    open,
    handleClose,
    isPublished,
    setPublishedEvaExist,
    existingEvaId,
    isPublishedEvaModel,
    isArchived = false,
    archive,
    setArchive,
    restarted,
    setProtocolIndex,
    protocolIndex,
    setArchiveEvaId,
}) => {
    const defaultGradientsData = [
        { name: 'Significant', number: 1 },
        { name: 'High', number: 0.8 },
        { name: 'Moderate', number: 0.6 },
        { name: 'Low', number: 0.4 },
        { name: 'Minimal', number: 0.2 },
    ];
    const initialTrigger = 50;
    const classes = useStyles();
    const { eva, metric } = useServices();
    const {
        createEva,
        updateEva,
        getEvaByOrganisationId,
        deleteEva,
        archiveEva,
        unarchiveEva,
        getEvaByEvaId,
        checkUserPermission,
    } = useOrganisationForm();
    const { id: organisationId } = useParams<{ id: string }>();
    const { t } = useTranslation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [iconState, setIconState] = useState('>');
    const [isIconsShow, setIsIconsShow] = useState(false);
    const sliderRef = useRef<Slider | null>(null);
    const divRef = useRef(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [triggerNumber, setTriggerNumber] = useState<number>(initialTrigger);
    const [copyTriggerNumber, setCopyTriggerNumber] = useState<number>(initialTrigger);
    const [metaMarkerValue, setMetaMarkerValue] = useState<string>('');
    const [weight, setWeight] = useState<string>('1');
    const [inputValues, setInputValues] = useState<{ nuance: string; threshold: number }[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [metaMarker, setMetaMarker] = useState<any>([]);
    const [isCreateMetaMarkerOpen, setIsCreateMetaMarkerOpen] = useState<null | HTMLElement>(null);
    const [addNuance, setAddNuance] = useState(false);
    const [evaId, setEvaId] = useState<string | undefined>();
    const [validationErrorMessage, setValidationErrorMessage] = useState({
        title: false,
        description: false,
        metaMarker: false,
        weight: false,
        nuance: false,
    });
    const [isTestMode, setIsTestMode] = useState(false);
    const [markerIndex, setMarkerIndex] = useState<number | null>(null);
    const [isNuanceRemoved, setIsNuanceRemoved] = useState<boolean>(false);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false);
    const [isMetaMarkerEnabled, setIsMetaMarkerEnabled] = useState(false);
    const [evaSlide, setEvaSlide] = useState(false);
    const [addMetaMarker, setAddMetaMarker] = useState(false);
    const [updateMetaMarker, setUpdateMetaMarker] = useState(false);
    const [gradients, setGradients] = useState<Gradients[]>(defaultGradientsData);
    const [generatedEva, setGeneratedEva] = useState('');
    const [isOpenPublishModal, setIsOpenPublishModal] = useState(false);
    const [metaMarkerDialog, setMetaMarkerDialog] = useState(false);
    const [testModeDialog, setTestModeDialog] = useState(false);
    const { setIsOpen } = useTour();
    const [isLoading, setIsLoading] = useState(false);
    const [isOpenRestartModal, setIsOpenRestartModal] = useState(false);
    const [isEvaPublished, setEvaPublished] = useState(false);
    const [creationMethod, setCreationMethod] = useState<string | null>(null);
    const [isRestarted, setIsRestarted] = useState(restarted);
    const [publishedAt, setPublishedAt] = useState<string | null>(null);
    const [openDrawer, setOpenDrawer] = React.useState(false);
    const [updateDate, setUpdateData] = useState('');
    // eva title and description toggle
    const [editTitle, setEditTitle] = useState(false);
    const [editDescription, setEditDescription] = useState(false);
    const refModal = useRef<HTMLDivElement>(null);
    const onboardingTour = store.user.userData?.onboardingTour;
    const { updateOnboardingTour } = useSettings();

    // sidebar component state  use both component parent and child
    const formattedData: Nuances[] = [];
    const [saveConfirmModal, setSaveConfirmModal] = useState(false);
    const [selectedItemIndex, setSelectedItemIndex] = useState<number | null>(null);
    const [nuancesValidate, setNuancesValidate] = useState<boolean>(false);
    const [metaMarkerValidation, setMetaMarkerValidation] = useState<boolean>(false);
    const [checkNuancesLeg, setCheCkNuancesLeg] = useState<boolean>(false);
    const [currentThreshold, setCurrentThreshold] = useState<number | null>(null);
    const [values, setValues] = useState<number[]>([]);
    const [data, setData] = useState<ThumbData[]>([]);
    const [copyData, setCopyData] = useState<ThumbData[]>([]);
    const [orbitLoader, setOrbitLoader] = useState(false);
    const [clickOutSideSideBar, setClickOutSideSideBar] = useState<boolean>(false);
    const [publishData, setPublishData] = useState<any>();
    const [metaMarkerData, setMetaMarkerData] = useState<MetaMarkerItem[]>([]);
    const [filteredItems, setFilteredItems] = useState<MetaMarkerItem[]>([]);
    const [labelShow, setLabelShow] = useState(true);
    const [holdSidebar, setHoldSidebar] = useState<boolean>(false);
    const [titleFocus, setTitleFocus] = useState(false);
    const [descriptionFocus, setDescriptionFocus] = useState(false);
    const titleInputRef = useRef<HTMLInputElement>(null);
    const descriptionInputRef = useRef<HTMLTextAreaElement>(null);

    // select box
    const [titleName, setTitleName] = useState<string>('Custom');
    const [CopySelect, setCopySelect] = useState<string>('Custom');

    const [isCheckSelf, setIsCheckSelf] = useState<boolean>(true);
    const [CopyIsSelf, setCopyIsSelf] = useState<boolean>(true);

    // nuance mapping
    const [apiNuances, setApiNuances] = useState<Nuances[]>([]);
    const [changeNuancesScreen, setChangeNuancesScreen] = useState(false);

    const history = useHistory();

    const titleRef = useRef<HTMLDivElement | null>(null);
    const descriptionRef = useRef<HTMLDivElement | null>(null);
    const isCreateEvaFromBlueprints = useSelector((state: RootState) => state.EvaInfo.isCreateEvaFromBlueprints);
    const { organisation } = useServices();

    // evaluateMode
    const [evaluateMode, setEvaluateMode] = useState<boolean>(false);
    const [evaluateHint, setEvaluateHint] = useState<string | null>(null);
    const [actionType, setActionType] = useState<string | null>(null);
    const [newNuances, setNewNuances] = useState<{ nuance: string; threshold: number; oldNuances?: string }>({
        nuance: '',
        threshold: 0,
        oldNuances: '',
    });
    const [editUserResponse, setEditUserResponse] = useState<boolean>(false);

    const [OrbitHover, setOrbitHover] = useState<boolean>(false);
    const [currentProtocolNuances, setCurrentProtocolNuances] = useState<Nuances[]>([]);

    //metricsMode
    const [metricsMode, setMetricsMode] = useState<boolean>(false);
    const [updateMetricProtocol, setUpdateMetricProtocol] = useState<boolean>(false);
    const [metricProtocolLoading, setMetricProtocolLoading] = useState<boolean>(false);

    const handleGenMetricProtocol = (
        mode: string,
        metaMarkerTitle: string,
        nuances: { nuance: string; threshold: number }[],
    ) => {
        if (mode === 'create') {
            setTriggerNumber(50);
            setIsCheckSelf(true);
            setMetaMarkerValue(metaMarkerTitle);
            setIconState('>');
            setInputValues(nuances);
        } else {
            setChangeNuancesScreen(true);
            setApiNuances(nuances);
        }
    };

    const compareFields = (publishData: MetaMarkerData): boolean => {
        const changeTitle = publishData?.title.trim() === title?.trim();
        const desc = publishData?.description.trim() === description.trim();
        const testMode = publishData.isTestMode === isTestMode;
        let metaMarkerText = true;
        let triggerNumber = true;
        let triggerState = true;
        let color = true;
        let nuancesData = true;

        if (markerIndex && metaMarker) {
            nuancesData = compareNuanceData(
                metaMarker[markerIndex]?.nuances,
                publishData?.metaMarkers[markerIndex]?.nuances,
            );
            metaMarkerText =
                publishData?.metaMarkers[markerIndex]?.metaMarker.trim() === metaMarker[markerIndex]?.metaMarker.trim();
            triggerNumber =
                publishData?.metaMarkers[markerIndex]?.triggerNumber === metaMarker[markerIndex]?.triggerNumber;
            triggerState =
                publishData?.metaMarkers[markerIndex]?.triggerState === metaMarker[markerIndex]?.triggerState;
            color = publishData?.metaMarkers[markerIndex]?.color === metaMarker[markerIndex]?.color;
        }

        if (
            changeTitle &&
            desc &&
            testMode &&
            metaMarkerText &&
            triggerNumber &&
            triggerState &&
            color &&
            nuancesData
        ) {
            return true;
        }
        return false;
    };
    // compare eva data and publish eva data then publish button enable / disable
    useEffect(() => {
        if (publishData) {
            if (compareFields(publishData)) {
                setEvaPublished(true);
            } else {
                setEvaPublished(false);
            }
        } else {
            setEvaPublished(false);
        }

        if (metaMarker.length !== publishData?.metaMarkers.length) {
            setEvaPublished(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [title, description, publishData, isTestMode, metaMarker, titleName, isCheckSelf, open]);

    const subscription = useSubscriptionDetail();

    const toggleEditTitle = async () => {
        setEditTitle(!editTitle);
        setEditDescription(false);
    };

    const toggleEditDescription = async () => {
        setEditDescription(!editDescription);
        setEditTitle(false);
    };

    // compare eva data and publish eva data if eva data is submit but not a publish enable publish button
    useEffect(() => {
        if (publishData && metaMarker) {
            if (!compareMetaMarkers(metaMarker, publishData?.metaMarkers)) {
                setEvaPublished(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publishData, metaMarker, open]);

    const handleEvent = () => {
        if (data) {
            // Check if data is not null or undefined
            if (openDrawer) {
                setCopyData(data); // Set data into copyData state
            }
        }
    };

    useEffect(() => {
        let intervalId: NodeJS.Timeout; // Define the type of intervalId
        if (openDrawer) {
            intervalId = setInterval(() => {
                handleEvent();
                if (copyData.length > 1) {
                    clearInterval(intervalId); // Stop the interval if copyData length &gt; 1
                }
            }, 200);
            if (setProtocolIndex) {
                setProtocolIndex(null);
            }
        }

        return () => {
            clearInterval(intervalId); // Clean up by clearing the interval on component unmount or when openDrawer changes
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openDrawer, copyData]); // Dependency array to watch for changes in openDrawer and copyData

    const inputElement = document.querySelector('.title_input') as HTMLInputElement;

    inputElement?.addEventListener('focus', function () {
        this.placeholder = '';
    });

    inputElement?.addEventListener('blur', function () {
        this.placeholder = `PROTOCOL#${
            (markerIndex || markerIndex === 0) && typeof markerIndex === 'number'
                ? markerIndex + 1
                : metaMarker.length + 1
        }`;
    });

    const { data: isUserAdmin } = useQuery(['checkUserPermission', organisationId], () => {
        try {
            return checkUserPermission(organisationId as string);
        } catch (e) {
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    useEffect(() => {
        // This is used when we have only draft eva
        const fetchEvaData = async () => {
            setIsLoading(true);
            const publishedEva = await getEvaByOrganisationId(organisationId, EvaType.publish);

            if (publishedEva.isPublished) {
                setPublishData(publishedEva);
            }

            if (publishedEva.id !== null && publishedEva.isPublished) {
                setPublishedEvaExist(true);
            }

            const eva =
                isArchived && existingEvaId
                    ? await getEvaByEvaId(existingEvaId, EvaType.draft)
                    : await getEvaByOrganisationId(organisationId, EvaType.draft);

            if (eva.id !== null) {
                setEvaId(eva.id);
                setTitle(eva.title || '');
                setIsTestMode(eva.isTestMode);
                setUpdateData(eva?.lastUpdatedAt || '');
                setDescription(eva.description || '');
                const metaMarkersData = eva?.metaMarkers.map((item: MetaMarkerItem, index: number) => {
                    // Check if protocolType is an error object, set "Custom", otherwise use the default value
                    const protocolType =
                        typeof item.protocolType === 'object' && 'error' in item.protocolType
                            ? 'Custom'
                            : item.protocolType;
                    return {
                        ...item,
                        protocolType, // Use the updated protocolType
                        indexNum: index,
                    };
                });

                setMetaMarkerData(metaMarkersData);
                setFilteredItems(metaMarkersData);

                setMetaMarker(
                    eva?.metaMarkers?.slice(
                        0,
                        subscription?.productName === SubScriptionType.SHIELD
                            ? 10
                            : subscription?.productName === SubScriptionType.OVERSIGHT
                            ? 40
                            : eva?.metaMarkers.length,
                    ) || [],
                );

                setGradients(eva.gradients);
                setArchive(eva.isEvaArchived);
                setCreationMethod(eva.creationMethod);
                setIsRestarted(eva.isRestarted);
                setPublishedAt(eva.publishedAt);
                if (sliderRef.current && !eva.isRestarted) {
                    sliderRef.current.slickNext();
                }
            }
            setIsLoading(false);
        };

        if (existingEvaId !== '') {
            fetchEvaData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [organisationId, evaId, subscription]);

    useEffect(() => {
        if (
            (!isPublishedEvaModel || isPublishedEvaModel) &&
            sliderRef.current &&
            existingEvaId &&
            metaMarker.length > 0 &&
            !isRestarted
        ) {
            sliderRef.current.slickNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPublishedEvaModel, existingEvaId, sliderRef.current]);

    const handleValidation = (type: string, value: string | boolean) => {
        setValidationErrorMessage((prevState) => ({
            ...prevState,
            [type]: typeof value === 'boolean' ? value : !value?.toString().trim(),
        }));
    };

    const validateTitleDescription = () => {
        handleValidation('title', title);
        handleValidation('description', description);
    };
    const validatePopOverItems = () => {
        handleValidation('metaMarker', metaMarkerValue);
        handleValidation('weight', weight);
        const nuanceValidation = inputValues.some((value) => value.nuance.trim() === '');
        handleValidation('nuance', nuanceValidation);
    };

    const handleDlClick = (event: React.MouseEvent<HTMLElement>, index?: number | null) => {
        setIsCreateMetaMarkerOpen(isCreateMetaMarkerOpen === event?.currentTarget ? null : event.currentTarget);
        setSaveConfirmModal(false);
        if (index !== null || index == undefined) {
            setOpenDrawer(true);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const hasMetaMarker =
        metaMarker?.length && metaMarker.every((item: MetaMarkerItem) => item.metaMarker.trim() !== '');

    const allMetaMarkersHaveTwoNonEmptyNuances =
        metaMarker.length &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        metaMarker.every((metaMarker: MetaMarkerItem) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const nonEmptyNuances = metaMarker.nuances.filter((nuance: Nuances) => {
                return nuance.nuance.trim() !== '';
            });
            return nonEmptyNuances.length >= 2;
        });

    // Disable the "Save Draft" button if there is no metaMarker and not at-least two nuance in nuances
    const isPublishEnable = hasMetaMarker && allMetaMarkersHaveTwoNonEmptyNuances;

    useEffect(() => {
        if (openDrawer && currentSlide === 0) {
            setIsCreateMetaMarkerOpen(null);
        }
    }, [openDrawer, currentSlide]);

    const handleFocus = () => {
        if (title !== '' && description !== '') {
            setIsIconsShow(true);
        }
    };

    useEffect(() => {
        const handleWheel = (event: WheelEvent) => {
            if ((generatedEva !== 'cloud' || evaId) && (!isRestarted || creationMethod === EvaCreationMethod.Blank)) {
                if (title !== '' && description !== '') {
                    if (event.deltaY > 0) {
                        // Scroll down
                        sliderRef.current?.slickNext();
                    }
                }
            }
        };

        const keyDownHandler = (event: KeyboardEvent) => {
            if (currentSlide == 0 && event.key === 'Enter') {
                validateTitleDescription();
            }

            if (currentSlide == 1 && event.key === 'Enter') {
                validatePopOverItems();
            }
            if (
                metaMarkerValue === '' ||
                weight === '' ||
                formattedData.filter((value) => value.nuance.trim() !== '').length < 2
            ) {
                return;
            }
            if (openDrawer) {
                const nuanceValidation = formattedData.some((value) => value.nuance === '');
                if (nuanceValidation && event.key === 'Enter') {
                    handleValidation('nuance', nuanceValidation);
                    return;
                }
                if (event.key === 'Enter') {
                    event.preventDefault();
                    handleAdd();
                    setMarkerIndex(null);
                    setIsCreateMetaMarkerOpen(null);
                }
            }
        };

        document.addEventListener('wheel', handleWheel);
        document.addEventListener('keydown', keyDownHandler);
        return () => {
            document.removeEventListener('wheel', handleWheel);
            document.removeEventListener('keydown', keyDownHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaMarker, title, description, inputValues, currentSlide]);

    // use for check button disable

    const settings = {
        className: `${
            currentSlide === 0 ? 'slider_one' : 'slider_two'
        } 'screen_slick_wrapper h-full outline-none overflow-hidden'`,
        dots: openDrawer || currentSlide === 1 ? false : true,
        infinite: false,
        scroll: true,
        speed: 500,
        draggable: false,
        MouseEvent: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        cssEase: 'ease',
        accessibility: false,
        waitForAnimate: true,
        height: '100%',
        arrows: false,
        beforeChange: (oldIndex: number, newIndex: number) => setCurrentSlide(newIndex),
        customPaging: () => <button disabled={title.trim() === '' || description.trim() === ''} />,
    };

    const handleIconClick = (iconValue: string) => {
        if (markerIndex !== null) {
            metaMarker[markerIndex].triggerState = iconValue;
            setIconState(metaMarker[markerIndex].triggerState);
        } else {
            setIconState(iconValue);
        }
    };

    useEffect(() => {
        const containerElement = document.querySelector('.marker_blue_box_content');
        if (containerElement !== undefined && containerElement !== null) {
            if (addNuance) {
                containerElement.scrollTop = containerElement.scrollHeight;
                setAddNuance(false);
            }
        }
    }, [addNuance]);

    // only for fetch Number Input after remove any nuances
    useEffect(() => {
        setIsNuanceRemoved(false);
    }, [isNuanceRemoved]);

    const handleAdd = async () => {
        if (!evaId && title !== '' && description !== '') {
            setMetaMarker((prevMetaMarker: MetaMarkerItem[]) => [
                ...prevMetaMarker,
                {
                    triggerNumber: triggerNumber,
                    triggerState: iconState,
                    color: getRgbColorCode(values),
                    metaMarker: metaMarkerValue,
                    weight: 1,
                    nuances: [...formattedData],
                    protocolType: titleName,
                    isSelfCategorization: isCheckSelf,
                },
            ]);
            setAddMetaMarker(true);
            setOrbitLoader(false);
        } else {
            if (markerIndex !== null) {
                setUpdateMetaMarker(true);
                // If markerIndex clicked, update its data
                metaMarker[markerIndex] = {
                    triggerNumber: parseFloat(triggerNumber.toString()),
                    triggerState: iconState,
                    color: getRgbColorCode(values),
                    metaMarker: metaMarkerValue,
                    weight: 1,
                    nuances: [...formattedData],
                    protocolType: titleName,
                    isSelfCategorization: isCheckSelf,
                };

                // Update the metaMarker state with the updated array
                setMetaMarker([...metaMarker]);
                setOrbitLoader(false);
            } else {
                // If markerIndex is clicked, add new marker
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                setMetaMarker((prevMetaMarker: MetaMarkerItem[]) => [
                    ...prevMetaMarker,
                    {
                        triggerNumber: parseFloat(triggerNumber.toString()),
                        triggerState: iconState,
                        color: getRgbColorCode(values),
                        metaMarker: metaMarkerValue,
                        weight: parseFloat(weight),
                        nuances: [...formattedData],
                        protocolType: titleName,
                        isSelfCategorization: isCheckSelf,
                    },
                ]);
                setUpdateMetaMarker(true);
            }
        }
        setCopyData(data);
        setOrbitLoader(false);
        // setMarkerIndex(null);
        setClickOutSideSideBar(false);
    };

    useEffect(() => {
        if (markerIndex !== null) {
            // If a markIndex is clicked, update the local state with its data to validate error state
            setTriggerNumber(metaMarker[markerIndex]?.triggerNumber);
            setCopyTriggerNumber(metaMarker[markerIndex]?.triggerNumber);
            setMetaMarkerValue(metaMarker[markerIndex]?.metaMarker);
            setWeight(metaMarker[markerIndex]?.weight);
            setInputValues(metaMarker[markerIndex]?.nuances);
            setIconState(metaMarker[markerIndex]?.triggerState);
            setTitleName(metaMarker[markerIndex]?.protocolType);
            setCopySelect(metaMarker[markerIndex]?.protocolType);
            setIsCheckSelf(
                metaMarker[markerIndex]?.isSelfCategorization === undefined
                    ? false
                    : metaMarker[markerIndex]?.isSelfCategorization,
            );
            setCopyIsSelf(
                metaMarker[markerIndex]?.isSelfCategorization == undefined
                    ? false
                    : metaMarker[markerIndex]?.isSelfCategorization,
            );
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [markerIndex]);

    const clearStateData = () => {
        setTriggerNumber(initialTrigger);
        setMetaMarkerValue('');
        setWeight('1');
        setInputValues([]);
        setIconState('>');
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const putEva = async (saveType: EvaType, metaMarker: MetaMarkerItem[]) => {
        try {
            if (saveType === EvaType.draft) {
                setPublishData(null);
            }

            if (!evaId) return;

            const eva = await updateEva(
                { title, description, metaMarkers: metaMarker, gradients, saveType, isTestMode, blueprintJson: '' },
                organisationId,
                //@ts-ignore
                evaId,
            );

            if (!eva) return;

            // Handle publish case
            if (saveType === EvaType.publish && eva.isPublished) {
                setPublishData(eva);
                setPublishedEvaExist(true);
            }

            // Update state
            setUpdateMetaMarker(false);
            setEvaId(eva.id);
            setTitle(eva.title || '');
            setIsTestMode(eva.isTestMode || false);
            setDescription(eva.description || '');

            // Process metaMarkers and update state
            const metaMarkersData =
                eva.metaMarkers?.map((item: MetaMarkerItem, index: number) => {
                    const protocolType =
                        typeof item.protocolType === 'object' && 'error' in item.protocolType
                            ? 'Custom'
                            : item.protocolType;

                    return {
                        ...item,
                        protocolType,
                        indexNum: index,
                    };
                }) || [];

            setMetaMarkerData(metaMarkersData);
            setFilteredItems(metaMarkersData);
            setMetaMarker(metaMarkersData);

            // Set last object and input values
            const lastObject = metaMarkersData[metaMarkersData.length - 1];
            setInputValues(lastObject?.nuances);
            setGradients(eva.gradients);

            // Marker Index logic
            if (markerIndex === null) {
                setMarkerIndex(metaMarkersData.length - 1);
            } else {
                setIsCheckSelf(metaMarkersData[markerIndex]?.isSelfCategorization);
                setCopyIsSelf(metaMarkersData[markerIndex]?.isSelfCategorization);
                setTitleName(metaMarkersData[markerIndex]?.protocolType);
                setCopySelect(metaMarkersData[markerIndex]?.protocolType);
                setInputValues(metaMarker[markerIndex]?.nuances);
            }

            setIsLoading(false);
            setUpdateMetricProtocol(true);
            setTimeout(() => {
                setUpdateMetricProtocol(false);
            }, 200);
            // Handle conflict case (status 409)
        } catch (error: any) {
            console.error('Error updating Eva:', error);
            setIsLoading(false);
            setUpdateMetricProtocol(false);
        }
    };

    const addEva = async (saveType: EvaType, data?: MetaMarkerData) => {
        setTitleName('Custom');
        if (evaId === undefined) {
            // create eva
            const payload = {
                title,
                description,
                metaMarkers: metaMarker,
                gradients,
                saveType,
                isTestMode,
                blueprintJson: '',
                creationMethod: EvaCreationMethod.Blank,
            };

            setAddMetaMarker(false);
            const eva = await createEva(data ? data : payload, organisationId);
            if (eva) {
                setEvaId(eva.id);
                setTitle(eva.title || '');
                setIsTestMode(eva.isTestMode || false);
                setDescription(eva.description || '');
                setMetaMarker(eva.metaMarkers || []);
                setGradients(eva.gradients);
                setTitleName(eva.protocolType);
                if (saveType === EvaType.publish) {
                    setPublishedEvaExist(true);
                }
                if (markerIndex === null && openDrawer) {
                    setMarkerIndex(0);
                }
            }
        }
    };

    useEffect(() => {
        const updateEva = async () => {
            if (updateMetaMarker) {
                await putEva(EvaType.draft, metaMarker);
            }
            if (addMetaMarker) {
                await addEva(EvaType.draft);
            }
        };

        updateEva();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metaMarker, addMetaMarker, updateMetaMarker]);

    const handleSubmit = async (saveType: EvaType, data?: MetaMarkerData) => {
        if (evaId === undefined) {
            // create eva
            await addEva(saveType, data);
            setTitleName('Custom');
        }
        if (evaId) {
            // update eva
            await putEva(saveType, metaMarker);
            handleClose();
            history.push(`/organisation/${organisationId}/eva`);
        }
        if (saveType === EvaType.publish) setPublishedAt(new Date().toISOString());
    };

    const handleDeleteEva = async () => {
        if (evaId) {
            await deleteEva(organisationId, evaId);
            setPublishedEvaExist(false);
            remove();
            setTitle('');
            setDescription('');
            //update the archive list if eva is archived then deleted.
            if (archive) store.organisation.deleteArchivedEVA(evaId);
            handleClose();
            if (setArchiveEvaId) {
                setArchiveEvaId(null);
            }
        }
    };

    const handleArchiveEva = async () => {
        if (evaId) {
            await archiveEva(evaId);
            store.organisation.setPublishedEVAExist(false);
            setArchive(true);
            remove();
        }
    };

    const handleUnarchiveEva = async () => {
        try {
            if (evaId && organisationId) {
                const publishObj: MetaMarkerData = {
                    creationMethod: creationMethod as EvaCreationMethod,
                    description: description,
                    title: title,
                    saveType: EvaType.publish,
                    gradients: gradients,
                    isTestMode: isTestMode,
                    metaMarkers: metaMarker,
                    blueprintJson: '',
                };
                const archivedEVAs = await unarchiveEva(organisationId, evaId, publishObj, true);
                store.organisation.setArchivedEVAs(archivedEVAs);
                store.organisation.setPublishedEVAExist(true);
                setEvaPublished(true);
                handleClose();
                if (setArchiveEvaId) {
                    setArchiveEvaId(null);
                }
            }
        } catch (error) {
            appInsights.trackException({ exception: error as Error });
        }
    };

    const handleEvaRestart = async () => {
        try {
            if (evaId && organisationId) {
                const evaResponse = await eva.restartEva(evaId);
                if (evaResponse) {
                    setCreationMethod(evaResponse.creationMethod);
                    setIsRestarted(true);
                    setMetaMarker([]);
                    //going back to the first slide
                    sliderRef.current?.slickPrev();
                }
            }
        } catch (error) {
            appInsights.trackException({ exception: error as Error });
        }
    };

    useEffect(() => {
        if (title.trim() !== '' && description.trim() !== '' && currentSlide == 0) {
            setIsIconsShow(true);
        } else {
            setIsIconsShow(false);
        }
    }, [title, description, currentSlide]);

    const bearer = localStorage.getItem('jwt') ?? undefined;
    const ENV_BASE_URL = process.env.REACT_APP_BACK_END_URL || 'http://localhost:3000';

    const {
        data: evaMetaMarkerData,
        isLoading: isLoadingMetaMarker,
        isFetching: isFetching,
        remove,
    } = useQuery(
        'metaMarkerData',
        async () => {
            const response = await fetch(`${ENV_BASE_URL}/organisation/${organisationId}/eva`, {
                headers: {
                    Authorization: `Bearer ${bearer}`,
                    'Content-Type': 'application/json',
                },
                method: 'POST',
                body: JSON.stringify({
                    input: {
                        title,
                        description,
                        metaMarkers: metaMarker,
                        gradients,
                        saveType: EvaType.draft,
                        isTestMode,
                        blueprintJson: '',
                        creationMethod: EvaCreationMethod.Cloud,
                    },
                    organisationId,
                    isCloudApiCall: isMetaMarkerEnabled,
                }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        },
        {
            enabled: isMetaMarkerEnabled,
            onSettled: () => {
                setIsMetaMarkerEnabled(false);
            },
        },
    );
    const handleClickEnter = async () => {
        if (title !== '' && description !== '' && !isRestarted) {
            if (generatedEva === 'cloud') {
                setIsMetaMarkerEnabled(true);
            } else {
                if (evaId) {
                    await putEva(EvaType.draft, metaMarker);
                }
            }
            sliderRef.current?.slickNext();
            if (onboardingTour?.startTour && !onboardingTour.evaMetamarker) {
                setMetaMarkerDialog(true);
            }
        } else if (isRestarted) {
            //if eva is restarted then we need to update the eva based on the creation method
            if (creationMethod === EvaCreationMethod.Blank) {
                sliderRef.current?.slickNext();
            }
            if (creationMethod === EvaCreationMethod.Cloud) {
                sliderRef.current?.slickNext();
                await putEva(EvaType.draft, metaMarker);
            }
            if (creationMethod === EvaCreationMethod.Template) {
                sliderRef.current?.slickNext();
                await putEva(EvaType.draft, metaMarker);
            } else {
                sliderRef.current?.slickNext();
            }
        }
    };
    const handleMetaMarker = async (data: string) => {
        setIsOpen(false);
        setEvaSlide(true);
        setGeneratedEva(data);
        sliderRef.current?.slickNext();
    };
    const dispatch = useDispatch();

    useEffect(() => {
        if (evaMetaMarkerData) {
            setEvaId(evaMetaMarkerData.id);
            setTitle(evaMetaMarkerData.title || '');
            setIsTestMode(evaMetaMarkerData.isTestMode);
            setDescription(evaMetaMarkerData.description || '');
            setMetaMarker(evaMetaMarkerData.metaMarkers || []);
            setGradients(evaMetaMarkerData.gradients);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [evaMetaMarkerData]);

    const createEvaMetaMarker = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();
        if (currentSlide == 0) {
            validateTitleDescription();
        }
        if (currentSlide == 1) {
            validatePopOverItems();
        }

        if (formattedData?.filter((value) => value?.nuance?.trim() !== '').length < 2) {
            setNuancesValidate(true);
            return;
        } else {
            setNuancesValidate(false);
        }

        if (metaMarkerValue.trim() === '') {
            setMetaMarkerValidation(true);
            return;
        } else {
            setMetaMarkerValidation(false);
        }

        if (openDrawer) {
            const nuanceValidation = formattedData.some((value) => value.nuance === '');
            if (nuanceValidation) {
                handleValidation('nuance', nuanceValidation);
                return;
            }
            handleAdd();
        }

        setIsCreateMetaMarkerOpen(null);
    };

    const deleteEvaMetaMarker = async (metamarkerId: number | null, saveType: EvaType) => {
        if (metamarkerId || metamarkerId === 0) {
            const newArray = [...metaMarker];
            const deletedMetaMarker = newArray[metamarkerId];
            newArray.splice(metamarkerId, 1);
            setMetaMarker(newArray);
            setIsCreateMetaMarkerOpen(null);
            try {
                await putEva(saveType, newArray);
                if (evaId && deletedMetaMarker)
                    metric.updateProtocolStatusOnDelete(evaId, deletedMetaMarker.metaMarker);
            } catch (error) {
                appInsights.trackException({ exception: error as Error });
            }
        } else {
            setValidationErrorMessage({
                title: false,
                description: false,
                metaMarker: false,
                weight: false,
                nuance: false,
            });
            setIsCreateMetaMarkerOpen(null);
            setMarkerIndex(null);
            clearStateData();
        }
        setCheCkNuancesLeg(false);
        setOrbitLoader(false);
        setMarkerIndex(null); // Reset the markerIndex after add marker
        setOpenDrawer(false);
        setEvaPublished(false);
    };

    const handleDescriptionClick = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (title !== '' && description !== '') {
                if (generatedEva === 'cloud') {
                    setIsMetaMarkerEnabled(true);
                }
                sliderRef.current?.slickNext();
            }
        }
    };
    const confirmSaveData = () => {
        setOpenDrawer(false);
    };

    const handleCloseTourDialog = () => {
        if (metaMarkerDialog) {
            setMetaMarkerDialog(false);
            setTestModeDialog(true);
            updateOnboardingTour(true, 'evaMetamarker');
        } else {
            setTestModeDialog(false);
            updateOnboardingTour(true, 'evaTestMode');
            updateOnboardingTour(false, 'startTour');
        }
    };

    const { isLoading: isLoadingArchive, refetch } = useQuery(
        ['archive-current-eva', organisationId],
        async () => {
            try {
                const data = await organisation.archiveCurrentEva(organisationId);
                if (data.status === 200) {
                    setPublishedEvaExist(false);
                    if (setArchiveEvaId) {
                        setEvaId(undefined);
                        setArchiveEvaId(null);
                    }
                }
                return data;
            } catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
            }
        },
        {
            enabled: false,
        },
    );

    const AddArchived = () => {
        refetch();
    };

    const handleActionButtonClick = () => {
        setInputValues((prevValues) => {
            const index = prevValues.findIndex((item) => item.nuance === newNuances.oldNuances);

            if (index !== -1) {
                // Update the existing protocol
                const updatedValues = [...prevValues];
                updatedValues[index] = { nuance: newNuances.nuance, threshold: newNuances.threshold };
                return updatedValues;
            } else {
                const newProtocol = { nuance: newNuances.nuance, threshold: newNuances.threshold };
                // Add the new protocol
                return [...prevValues, newProtocol];
            }
        });
    };

    // title and description hide show edit icon
    const handleClickOutside = (event: MouseEvent) => {
        if (
            titleRef.current &&
            !titleRef.current.contains(event.target as Node) &&
            descriptionRef.current &&
            !descriptionRef.current.contains(event.target as Node)
        ) {
            setEditTitle(false);
            setEditDescription(false);
        }
    };
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleTitleFocus = () => {
        setTitleFocus(true);
        setDescriptionFocus(false);
        setTimeout(() => {
            titleInputRef.current?.focus(); // Ensure the input gets focus after state update
        }, 0);
    };

    const handleDescriptionFocus = () => {
        setDescriptionFocus(true);
        setTitleFocus(false);
        setTimeout(() => {
            descriptionInputRef.current?.focus(); // Ensure the input gets focus after state update
        }, 0);
    };

    return (
        <>
            {/* Archive EVA Confirmation Modal From create a blueprint  */}
            {!isLoading && (
                <EvaDialog
                    open={isCreateEvaFromBlueprints}
                    setClose={() => !isLoadingArchive && dispatch(SetIsCreateEvaFromBlueprints(false))}
                    onConfirm={AddArchived}
                    disabled={false}
                    isConfirmDisabled={isLoadingArchive}
                    title={t('create_blueprint')}
                    content={<div dangerouslySetInnerHTML={{ __html: t('archive_blueprint_warning', { title }) }} />}
                    confirmButtonText={t('confirm')}
                    buttonVariant='bberryBlue'
                />
            )}

            <EvaDialog
                open={isOpenRestartModal}
                setClose={setIsOpenRestartModal}
                onConfirm={handleEvaRestart}
                disabled={false}
                title={t('restart_blueprint')}
                content={<div dangerouslySetInnerHTML={{ __html: t('restart_delete_warning', { title }) }} />}
                confirmButtonText={t('restart')}
                buttonVariant='primaryDarkBlue'
            />
            <EvaDialog
                open={isOpenPublishModal}
                setClose={setIsOpenPublishModal}
                onConfirm={handleUnarchiveEva}
                disabled={!isPublishEnable}
                title={t('set_as_main')}
                content={<div dangerouslySetInnerHTML={{ __html: t('replace_blueprint_warning', { title }) }} />}
                confirmButtonText={t('btnNames.publish')}
                buttonVariant='bberryBlue'
            />
            <EvaDialog
                open={isOpenDeleteModal}
                setClose={setIsOpenDeleteModal}
                onConfirm={handleDeleteEva}
                disabled={false}
                title={t('delete_eva')}
                content={<div dangerouslySetInnerHTML={{ __html: t('delete_warning', { title }) }} />}
                confirmButtonText={t('btnNames.delete')}
                buttonVariant='rejectRed'
            />
            <Dialog fullScreen open={open} onClose={handleClose} className='main_modal'>
                <div
                    id='blue_print_modal'
                    className='relative flex flex-col w-full bg-dark-blue-radial z-10 blue_print_box'
                >
                    {!existingEvaId && !evaSlide ? (
                        <EvaTemplate
                            closeModal={handleClose}
                            handleChangeSlide={() => {
                                setEvaSlide(true);
                                //to go to protocol page when user click on next button
                                setCurrentSlide(1);
                                sliderRef.current?.slickNext();
                            }}
                            handleMetaMarker={handleMetaMarker}
                            isLoading={isLoadingMetaMarker}
                            handleSubmit={handleSubmit}
                            organisationId={organisationId}
                        />
                    ) : (
                        <>
                            <div className='blue_print_top'>
                                {evaSlide || existingEvaId ? (
                                    <>
                                        {currentSlide === 1 && (
                                            <div className={`  ${classes.headerTitle} eva_top_head`}>
                                                <div className='eva_content'>
                                                    <div ref={titleRef} onBlur={() => setEditTitle(false)} tabIndex={0}>
                                                        <EvaTitleDescription
                                                            input={title}
                                                            size='base '
                                                            setInput={setTitle}
                                                            handleValidation={handleValidation}
                                                            type='title'
                                                            maxLength={50}
                                                            className='eva_top_title'
                                                            font='medium'
                                                            fontWeight='semibold'
                                                            edit={editTitle}
                                                            toggleEdit={toggleEditTitle}
                                                        />
                                                    </div>
                                                    <div
                                                        ref={descriptionRef}
                                                        onBlur={() => setEditDescription(false)}
                                                        tabIndex={0}
                                                    >
                                                        <EvaTitleDescription
                                                            input={description}
                                                            size='sm eva_top_disc'
                                                            setInput={setDescription}
                                                            handleValidation={handleValidation}
                                                            type='description'
                                                            maxLength={150}
                                                            font='small'
                                                            className='eva_top_disc'
                                                            fontWeight='normal'
                                                            edit={editDescription}
                                                            toggleEdit={toggleEditDescription}
                                                        />
                                                    </div>
                                                </div>

                                                <Button
                                                    size='oval'
                                                    className='eva_btn eva_orbit'
                                                    variant='evaRestart'
                                                    disabled={!isUserAdmin}
                                                    onClick={() => {
                                                        setLabelShow(!labelShow);
                                                    }}
                                                    onMouseEnter={() => setOrbitHover(true)}
                                                    onMouseLeave={() => setOrbitHover(false)}
                                                >
                                                    {OrbitHover ? (
                                                        <img
                                                            src={labelShow ? miniOrbitHover : miniOrbitwhite}
                                                            alt=''
                                                            className={`${
                                                                labelShow ? 'orbit_hover' : ''
                                                            } cursor-pointer`}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={labelShow ? miniOrbit : miniOrbitwhite}
                                                            alt=''
                                                            className={`${
                                                                labelShow ? 'orbit_hover' : ''
                                                            } cursor-pointer`}
                                                        />
                                                    )}

                                                    <div className='tooltip_box'>
                                                        <p> {labelShow ? t('hide_labels') : t('show_labels')} </p>
                                                    </div>
                                                </Button>
                                            </div>
                                        )}
                                    </>
                                ) : null}

                                {currentSlide === 1 ? (
                                    <EvaHeader
                                        currentSlide={currentSlide}
                                        isArchived={isArchived}
                                        isTestMode={evaluateMode}
                                        setIsTestMode={setEvaluateMode}
                                        evaId={evaId}
                                        publishedAt={publishedAt}
                                        archive={archive}
                                        isUserAdmin={isUserAdmin}
                                        handleArchiveEva={handleArchiveEva}
                                        handleUnarchiveEva={handleUnarchiveEva}
                                        setIsOpenRestartModal={setIsOpenRestartModal}
                                        setIsOpenDeleteModal={setIsOpenDeleteModal}
                                        setIsOpenPublishModal={setIsOpenPublishModal}
                                        handleSubmit={handleSubmit}
                                        setEvaPublished={setEvaPublished}
                                        isPublishEnable={isPublishEnable}
                                        isEvaPublished={isEvaPublished}
                                        setLabelShow={setLabelShow}
                                        labelShow={labelShow}
                                        handleClose={handleClose}
                                        isPublished={isPublished}
                                        setEditUserResponse={setEditUserResponse}
                                    />
                                ) : null}
                            </div>

                            {evaSlide || existingEvaId ? (
                                <div className='relative h-full blue_print_eva'>
                                    {!openDrawer && (
                                        <>
                                            <ProtocolList
                                                metaMarkerData={metaMarkerData}
                                                setFilteredItems={setFilteredItems}
                                                filteredItems={filteredItems}
                                                setMarkerIndex={setMarkerIndex}
                                                handleDlClick={handleDlClick}
                                                setProtocolIndex={setProtocolIndex}
                                            />
                                        </>
                                    )}

                                    <Slider ref={sliderRef} {...settings}>
                                        {/* First Slider */}
                                        <div className='h-full overflow-hidden eva-first-slider-wrapper outline-none'>
                                            {!isLoading ? (
                                                <>
                                                    <div
                                                        ref={divRef}
                                                        onFocus={handleFocus}
                                                        tabIndex={0} // Make the div focusable
                                                        className='flex flex-1 justify-center items-center flex-col eva-first-slider h-full outline-none'
                                                    >
                                                        <div className='eva_before_img'>
                                                            <img src={planet} />
                                                        </div>
                                                        <Box
                                                            className='eva-first-slider-planet absolute text-center z-10'
                                                            width={description.length > 50 ? 280 : 235}
                                                            padding={description.length > 50 ? '8px 12px' : '8px'}
                                                        >
                                                            <Box className='grid'>
                                                                {/* Title Section */}
                                                                <div
                                                                    className='create_eva_input title'
                                                                    onClick={() => setDescriptionFocus(false)}
                                                                >
                                                                    {titleFocus ? (
                                                                        <input
                                                                            ref={titleInputRef}
                                                                            type='text'
                                                                            maxLength={50}
                                                                            onBlur={() => {
                                                                                setTitleFocus(false);
                                                                                handleValidation('title', title);
                                                                            }}
                                                                            onChange={(e) => setTitle(e.target.value)}
                                                                            value={title}
                                                                        />
                                                                    ) : (
                                                                        <p
                                                                            onClick={handleTitleFocus}
                                                                            className={
                                                                                title
                                                                                    ? 'title_text'
                                                                                    : 'placeholder_text'
                                                                            }
                                                                        >
                                                                            {title || t('title_max')}
                                                                            {!title && <span>*</span>}
                                                                        </p>
                                                                    )}

                                                                    {validationErrorMessage.title &&
                                                                        title.length === 0 && (
                                                                            <ErrorMessage message={t('enter_title')} />
                                                                        )}
                                                                </div>

                                                                {/* Description Section */}
                                                                <div
                                                                    className='create_eva_input description'
                                                                    onClick={() => setTitleFocus(false)}
                                                                >
                                                                    {descriptionFocus ? (
                                                                        <textarea
                                                                            ref={descriptionInputRef}
                                                                            id='metaMarker-description'
                                                                            maxLength={150}
                                                                            onBlur={() => {
                                                                                setDescriptionFocus(false);
                                                                                handleValidation(
                                                                                    'description',
                                                                                    description,
                                                                                );
                                                                            }}
                                                                            onChange={(e) =>
                                                                                setDescription(e.target.value)
                                                                            }
                                                                            value={description}
                                                                            onInput={(e) => {
                                                                                const textarea =
                                                                                    e.target as HTMLTextAreaElement;
                                                                                textarea.style.height = 'auto'; // Reset height to recalculate
                                                                                textarea.style.height = `${
                                                                                    !description
                                                                                        ? 18
                                                                                        : textarea.scrollHeight
                                                                                }px`; // Adjust height based on content
                                                                            }}
                                                                            onFocus={(e) => {
                                                                                const textarea =
                                                                                    e.target as HTMLTextAreaElement;
                                                                                textarea.style.height = 'auto'; // Reset height to recalculate
                                                                                textarea.style.height = `${
                                                                                    !description
                                                                                        ? 18
                                                                                        : textarea.scrollHeight
                                                                                }px`; // Adjust height when focused
                                                                            }}
                                                                            style={{
                                                                                overflow: 'hidden',
                                                                                resize: 'none',
                                                                            }} // Prevent manual resize and hide scrollbars
                                                                        />
                                                                    ) : (
                                                                        <p
                                                                            onClick={handleDescriptionFocus}
                                                                            className={
                                                                                description
                                                                                    ? 'description_text'
                                                                                    : 'placeholder_text'
                                                                            }
                                                                        >
                                                                            {description || t('description_max')}
                                                                            {!description && <span>*</span>}
                                                                        </p>
                                                                    )}

                                                                    {validationErrorMessage.description &&
                                                                        description.length === 0 && (
                                                                            <ErrorMessage
                                                                                message={t('enter_description')}
                                                                            />
                                                                        )}
                                                                </div>

                                                                {/* Submit Button */}
                                                                <button className='submit_btn' type='submit'>
                                                                    {t('btnNames.submit')}
                                                                </button>
                                                            </Box>
                                                        </Box>

                                                        {isIconsShow && (
                                                            <>
                                                                <div className='relative z-10'>
                                                                    <ArrowIcon
                                                                        onClick={handleClickEnter}
                                                                        className='bg-lightblue text-lightblue mx-auto h-4 w-5 mt-1 p-1 rounded cursor-pointer'
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className='absolute left-0 right-0 mx-auto'>
                                                        <p className='flex justify-center font-medium text-lightblue eva-footer'>
                                                            {t('give_description')}
                                                        </p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className='flex justify-center items-center eva_circle  w-100 h-100'>
                                                    <AnimatedCircle isThinking={isLoading} />
                                                </div>
                                            )}
                                        </div>

                                        {/* Second Slider */}
                                        {isLoadingMetaMarker || isFetching || isLoading ? (
                                            <CustomLoader />
                                        ) : (
                                            <div className='h-full overflow-hidden outline-none'>
                                                <ClickAwayListener onClickAway={handleCloseTourDialog}>
                                                    <TourDialog
                                                        open={metaMarkerDialog}
                                                        handleClose={handleCloseTourDialog}
                                                        top={200}
                                                        left={100}
                                                        type={'evaMetamarker'}
                                                        title={t('home.tour.metamarker.title')}
                                                        content={
                                                            <div className='space-y-2 text-white text-opacity-75 mt-4 text-xs'>
                                                                <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                    <p className='whitespace-nowrap'>{t('trigger')}</p>
                                                                </div>
                                                                <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                    <p className='whitespace-nowrap'>
                                                                        {' '}
                                                                        {t('metamarker')}{' '}
                                                                    </p>
                                                                </div>
                                                                <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                    <p className='whitespace-nowrap'>{t('weight')}</p>
                                                                </div>
                                                                <div className='flex space-x-2 bg-primary-tour p-4 items-center'>
                                                                    <p className='whitespace-nowrap'>{t('nuances')}</p>
                                                                </div>
                                                                <div>
                                                                    <Button
                                                                        className='flex space-x-2 mt-5'
                                                                        size='oval'
                                                                        variant='fillMetamarker'
                                                                    >
                                                                        <img
                                                                            src={fillMetamarkerIcon}
                                                                            alt='fill_metamarker'
                                                                        />
                                                                        <p>{t('fillMetamarker')}</p>
                                                                    </Button>
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </ClickAwayListener>
                                                <ClickAwayListener onClickAway={handleCloseTourDialog}>
                                                    <TourDialog
                                                        open={testModeDialog}
                                                        handleClose={handleCloseTourDialog}
                                                        top={-40}
                                                        left={130}
                                                        type={'evaTestMode'}
                                                        title={'Test mode'}
                                                        content={
                                                            <div className='space-y-2 text-white text-opacity-75 mt-4 text-xs'>
                                                                <p>
                                                                    <div
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: t('testBlueprint'),
                                                                        }}
                                                                    />
                                                                </p>
                                                            </div>
                                                        }
                                                    />
                                                </ClickAwayListener>
                                                <div className='flex flex-1 justify-center items-center h-full outline-none create_eva_main'>
                                                    {/* Render all orbits return */}
                                                    <div id='solar-system' className='saturn'>
                                                        <CreateEvaOrbit
                                                            createMarkerOpen={openDrawer}
                                                            handleDlClick={handleDlClick}
                                                            metaMarker={metaMarker}
                                                            setHoldSidebar={setHoldSidebar}
                                                            setMarkerIndex={setMarkerIndex}
                                                            labelShow={labelShow}
                                                            evaluateMode={evaluateMode}
                                                            currentSlide={currentSlide}
                                                            markerIndex={markerIndex}
                                                            subscription={subscription}
                                                            protocolIndex={protocolIndex}
                                                        />
                                                        <Drawer
                                                            open={openDrawer}
                                                            className={`${classes.drawer} eva_sidebar`}
                                                            hideBackdrop={true}
                                                            ref={refModal}
                                                            classes={{ paper: classes.drawerPaper }}
                                                            disableEnforceFocus
                                                        >
                                                            <Sidebar
                                                                createEvaMetaMarker={createEvaMetaMarker}
                                                                inputValues={inputValues}
                                                                setInputValues={setInputValues}
                                                                formattedData={formattedData}
                                                                selectedItemIndex={selectedItemIndex}
                                                                setSelectedItemIndex={setSelectedItemIndex}
                                                                nuancesValidate={nuancesValidate}
                                                                setNuancesValidate={setNuancesValidate}
                                                                metaMarkerValidation={metaMarkerValidation}
                                                                setMetaMarkerValidation={setMetaMarkerValidation}
                                                                checkNuancesLeg={checkNuancesLeg}
                                                                setCheCkNuancesLeg={setCheCkNuancesLeg}
                                                                currentThreshold={currentThreshold}
                                                                setCurrentThreshold={setCurrentThreshold}
                                                                values={values}
                                                                setValues={setValues}
                                                                setTriggerNumber={setTriggerNumber}
                                                                triggerNumber={triggerNumber}
                                                                openDrawer={openDrawer}
                                                                setMarkerIndex={setMarkerIndex}
                                                                markerIndex={markerIndex}
                                                                setMetaMarkerValue={setMetaMarkerValue}
                                                                metaMarkerValue={metaMarkerValue}
                                                                title={title}
                                                                iconState={iconState}
                                                                setIconState={setIconState}
                                                                setSaveConfirmModal={setSaveConfirmModal}
                                                                handleIconClick={handleIconClick}
                                                                setOpenDrawer={setOpenDrawer}
                                                                handleValidation={handleValidation}
                                                                metaMarker={metaMarker}
                                                                deleteEvaMetaMarker={deleteEvaMetaMarker}
                                                                refModal={refModal}
                                                                data={data}
                                                                setData={setData}
                                                                setOrbitLoader={setOrbitLoader}
                                                                clickOutSideSideBar={clickOutSideSideBar}
                                                                setClickOutSideSideBar={setClickOutSideSideBar}
                                                                titleName={titleName}
                                                                setTitleName={setTitleName}
                                                                setIsCheckSelf={setIsCheckSelf}
                                                                isCheckSelf={isCheckSelf}
                                                                setEvaluateMode={setEvaluateMode}
                                                                evaluateMode={evaluateMode}
                                                                CopySelect={CopySelect}
                                                                setCopySelect={setCopySelect}
                                                                updateDate={updateDate}
                                                                setCopyTriggerNumber={setCopyTriggerNumber}
                                                                setCopyIsSelf={setCopyIsSelf}
                                                                CopyIsSelf={CopyIsSelf}
                                                                setCurrentProtocolNuances={setCurrentProtocolNuances}
                                                                copyTriggerNumber={copyTriggerNumber}
                                                                disableArrow={editUserResponse}
                                                                setDisableArrow={setEditUserResponse}
                                                                metricsMode={metricsMode}
                                                                setMetricsMode={setMetricsMode}
                                                                isMetricLoading={metricProtocolLoading}
                                                                apiNuances={apiNuances}
                                                                setApiNuances={setApiNuances}
                                                                changeNuancesScreen={changeNuancesScreen}
                                                                setChangeNuancesScreen={setChangeNuancesScreen}
                                                                holdSidebar={holdSidebar}
                                                                evaId = {evaId}
                                                            />
                                                        </Drawer>
                                                        <div className='orbit-center-img'>
                                                            <AnimatedCircle
                                                                evaluateMode={evaluateMode}
                                                                isThinking={orbitLoader}
                                                            />
                                                        </div>
                                                    </div>
                                                    <svg className='lines-container' />
                                                    <RightSidePanel
                                                        evalMode={evaluateMode}
                                                        setEvalMode={setEvaluateMode}
                                                        metricsMode={metricsMode}
                                                        setMetricsMode={setMetricsMode}
                                                        evaId={evaId}
                                                        protocol={currentProtocolNuances}
                                                        protocolName={metaMarkerValue}
                                                        setEvaluateHint={setEvaluateHint}
                                                        setActionType={setActionType}
                                                        setOrbitLoader={setOrbitLoader}
                                                        setNewProtocol={setNewNuances}
                                                        setEditUserResponse={setEditUserResponse}
                                                        handleDlClick={handleDlClick}
                                                        setMarkerIndex={setMarkerIndex}
                                                        markerIndex={markerIndex}
                                                        protocolUpdated={updateMetricProtocol}
                                                        currentSlide={currentSlide}
                                                        genMetricProtocol={handleGenMetricProtocol}
                                                        setMetricLoading={setMetricProtocolLoading}
                                                    />
                                                    {/* evaluet response hint */}
                                                    {evaluateHint && evaluateMode && (
                                                        <EvaluateHint
                                                            actionType={actionType}
                                                            newNuances={newNuances}
                                                            evaluateHint={evaluateHint}
                                                            handleActionButtonClick={handleActionButtonClick}
                                                            protocolName={metaMarkerValue}
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                            ) : null}
                        </>
                    )}
                </div>
            </Dialog>

            <EvaDialog
                open={saveConfirmModal}
                setClose={setSaveConfirmModal}
                onConfirm={confirmSaveData}
                disabled={false}
                title={t('protocol_exit')}
                content={<div dangerouslySetInnerHTML={{ __html: t('close_without_saving', { metaMarkerValue }) }} />}
                confirmButtonText={t('btnNames.yes')}
                buttonVariant='primaryDarkBlue'
            />
        </>
    );
};

export default CreateEvaModel;
