import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import { ReactComponent as DownArrowFill } from '../../../../../../../../src/assets/icons/DownArrowFill.svg';
import { useSubscriptionDetail } from '../../../../../../../contexts/subscription.context';
import { setOversightOption } from '../../../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../../../Redux/store';
import { StarIcon } from '../../../../../../basic/StarIcon.component';
import FormSelect from '../../../../../../profile/Form/FormSelect';
import { SubScriptionType } from '../../../types';
import OversightNoReport from '../OversightSummaries/OversightNoReport';
import ReportList from '../OversightSummaries/ReportList';

interface OversightProps {
    setOversightSortVal?: React.Dispatch<React.SetStateAction<string>>;
    setOversightReportVal?: React.Dispatch<React.SetStateAction<string>>;
    oversightReportVal?: string;
    // fetchOversight: (type: string) => void;
    screen?: string;
    fetchOversight: (type: string) => void;
    setScreen?: React.Dispatch<React.SetStateAction<string>>;
}

const Oversight: React.FC<OversightProps> = ({ setOversightSortVal, fetchOversight, oversightReportVal, screen }) => {
    const sortType = ['Date', 'Sort A-Z', 'Sort Z-A', 'Recommended', 'Started', 'Not started'];
    const { t } = useTranslation();

    const dispatch = useAppDispatch();
    const option = useSelector((state: RootState) => state.SessionsProtocols.OversightOption);

    const selectReport = [
        t('executiveSummary'),
        t('governanceOverview'),
        t('riskManagement'),
        t('complianceStatus'),
        t('performanceProductivity'),
        t('strategicCompliance'),
        t('organizationalCulture'),
    ];

    const handleChangeReport = (name: string) => {
        dispatch(setOversightOption(name));
    };

    const ReportLoading = useSelector((state: RootState) => state.SessionsProtocols.ReportLoading);
    const { id } = useParams<{ id: string }>();
    const subscription = useSubscriptionDetail();
    const isShield = subscription?.productName === SubScriptionType?.SHIELD;

    return (
        <>
            <div className='summaries_heading flex justify-between items-center'>
                <div className='flex items-center gap-3'>
                    <h1> {t('oversight')} </h1>
                </div>
            </div>

            <div className={`${isShield ? 'shield_oversight' : ''} main_msg_details `}>
                <div className='oversight_top  flex justify-between items-center my-3'>
                    <div className='sort_box mt-0'>
                        <FormSelect
                            entries={selectReport}
                            value={option}
                            onChange={(e) => handleChangeReport(e)}
                            edit
                            placeholder={t('select')}
                            marginTop={42}
                            endIcon={<DownArrowFill />}
                            disabled={isShield}
                        />
                    </div>
                    <button
                        onClick={() => {
                            fetchOversight('guided');
                        }}
                        className={`create_report flex items-center justify-center ${
                            option.length === 0 ? 'disable' : ''
                        }`}
                        disabled={option?.length === 0}
                    >
                        {ReportLoading ? <div className='file_loading report_loader' /> : t('createReport')}
                    </button>
                </div>

                {isShield ? (
                    <>
                        <OversightNoReport
                            title={<h1>{t('no_report')}</h1>}
                            dis={
                                <h6>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: t('askEVA'),
                                        }}
                                    />
                                </h6>
                            }
                        />
                        <div className='isShield_start'>
                            <Link to={`/organisation/${id}/change-tier`}>
                                <StarIcon blackIcon={true} isChangePlusIcon={true} isHover={true} />
                            </Link>
                        </div>
                    </>
                ) : (
                    <ReportList />
                )}
            </div>
        </>
    );
};

export default Oversight;
