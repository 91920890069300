import { Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { appInsights } from '../../../AppInsights';
import { ReactComponent as CrossIcon } from '../../../assets/icons/icon-cross-fill.svg';
import { Button } from '../../basic/Button.component';
import { useServices } from '../../ServiceProvider';

type DeleteAccountDialogProps = {
    open: boolean;
    handleClose: () => void;
};

const DeleteAccountDialog = ({ open, handleClose }: DeleteAccountDialogProps) => {
    const { t } = useTranslation();
    const { users } = useServices();

    const deleteAccount = async () => {
        try {
            await users.deleteAccount();
            localStorage.setItem('jwt', '');
            alert(t('profile.delete.success'));
            window.location.reload();
        } catch (e) {
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
            alert(t('profile.delete.error'));
        }
    };

    return (
        <Dialog
            onClose={handleClose}
            open={open}
            PaperProps={{
                style: {
                    borderRadius: '15px',
                },
            }}
        >
            <div className='delete-dialog'>
                <div className='flex justify-between items-center pb-4 mb-4 border-b border-blue-dark border-opacity-10'>
                    <p className='text-darker text-opacity-75 text-sm font-medium'>Delete Account</p>
                    <button
                        onClick={() => {
                            handleClose();
                        }}
                    >
                        <CrossIcon fill='#0F104480' width={12} height={12} />
                    </button>
                </div>
                <div className='text-sm text-darker text-opacity-75'>
                    <div className='mb-4 py-3 px-4 flex items-center bg-notification-100 rounded-md w-full space-x-4 font-medium'>
                        <div className='w-6 h-6 flex items-center justify-center bg-warning-900 rounded-full text-tiny text-white'>
                            !
                        </div>
                        <p>{t('profile.delete.warning')}</p>
                    </div>
                    <p>{t('profile.delete.confirm')}</p>
                </div>

                <div className='flex justify-end space-x-4 mt-6'>
                    <Button
                        onClick={() => {
                            handleClose();
                        }}
                        variant='outlinedCancel'
                        size='rectSmall'
                    >
                        {t('btnNames.cancel')}
                    </Button>
                    <Button onClick={deleteAccount} variant='rejectRed' size='rectSmall'>
                        {t('btnNames.delete')}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default DeleteAccountDialog;
