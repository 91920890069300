import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';

import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState, useAppDispatch } from '../../../../../../Redux/store';
import { setSelectedIds, setSelectedMetricGraphData, setSelectedMetricIds } from '../../../../../../Redux/TrendMetric';
import { categoryList } from '../../../eva/components/metrics/metricsType';
import { MetricsOptionData } from '../../../eva/components/MetricsConfig';
import { MetricGraphDataType } from '../../components/analyticsChart/types';
import { calculateMaxYValue, getCategories, getTimeIntervalWithFilter } from './CustomChartFunction';

interface MetricsBarChartProps {
    timeFilter: string;
    isLoading: boolean;
    isFetching: boolean;
    dateRange: string[];
    setTimeFilter: Dispatch<SetStateAction<string>>;
    setBackTimeFilter: Dispatch<SetStateAction<string[]>>;
}

//default bar chart parameters
const MetricBarChart: React.FC<MetricsBarChartProps> = ({
    timeFilter,
    isLoading,
    isFetching,
    dateRange,
    setTimeFilter,
    setBackTimeFilter,
}) => {
    const { t } = useTranslation();
    const chartData = useSelector((state: RootState) => state.TrendMetric.chartData);
    const maxValue = Math.max(...chartData.flatMap((item) => item.data)) + 1;
    const timeIntervalCount = getTimeIntervalWithFilter(timeFilter);
    const selectedMetricIds = useSelector((state: RootState) => state.TrendMetric.selectedMetricIds);
    const [chartOptions, setChartOptions] = useState<ApexOptions>({});

    const dispatch = useAppDispatch();

    const maxYValue = calculateMaxYValue(maxValue);
    const filterFormat = dateRange;

    const categories = getCategories(timeFilter, filterFormat);

    const colorStops = chartData.map((item) => {
        const colorObj = categoryList.find((obj) => obj.key === item.category);
        return colorObj?.color;
    });

    const metricGraphData = {
        name: '',
        data: [],
        id: '',
        category: '',
        format: '',
    };

    const chartValues = useMemo(() => {
        if (isFetching || isLoading) {
            return [{ data: Array(timeIntervalCount).fill(0) }];
        }
        return chartData;
    }, [chartData, isFetching, isLoading, timeIntervalCount]);

    const tooltipDataRef = useRef<{ value: number | null; timeData: string; metricData: MetricGraphDataType }>({
        value: null,
        timeData: '',
        metricData: metricGraphData,
    });

    const getGraphData = () => {
        if (timeFilter === 'All') {
            const firstFormat = filterFormat[0];
            if (moment(firstFormat, 'MM-YYYY', true).isValid()) {
                setTimeFilter('Month');
            } else if (moment(firstFormat, 'YYYY', true).isValid()) {
                setTimeFilter('Year');
            }
        }
        if (timeFilter === 'Year') {
            setTimeFilter('Month');
        }

        if (timeFilter == 'Month' || timeFilter == 'Week') {
            setTimeFilter('Day');
        }
        const { value, timeData, metricData } = tooltipDataRef.current;

        if (metricData) {
            dispatch(setSelectedMetricIds([metricData?.id]));
            dispatch(setSelectedIds([metricData?.id]));
        }

        setBackTimeFilter((prevState) => {
            // Create a unique array of filters, including the new timeFilter
            const updatedState = Array.from(new Set([...prevState, timeFilter])); // Convert Set to array
            return updatedState;
        });

        dispatch(
            // Dispatch the action
            setSelectedMetricGraphData({
                metricValue: value,
                metricDrilledDate: timeData,
            }),
        );
    };

    //default options

    useEffect(() => {
        const options: ApexOptions = {
            chart: {
                width: '100%',
                toolbar: {
                    show: false,
                },
                zoom: {
                    enabled: false,
                },
            },
            dataLabels: {
                enabled: false,
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 5,
                    columnWidth: '10px',
                    borderRadiusApplication: 'end',
                },
            },
            colors: colorStops,
            xaxis: {
                categories: categories,
                labels: {
                    show: true,
                    style: {
                        colors: 'rgba(15, 16, 68, 0.75)',
                        fontSize: '10px',
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                    },
                },
                position: 'bottom',
                axisTicks: {
                    show: false,
                },
                axisBorder: {
                    show: false,
                    offsetX: -3,
                    offsetY: -3,
                },
            },
            yaxis: {
                labels: {
                    show: true,
                    style: {
                        colors: 'rgba(15, 16, 68, 0.75)',
                        fontSize: '10px',
                        fontFamily: 'Poppins',
                        fontWeight: '400',
                    },
                    offsetX: 0,
                    offsetY: -1,
                    formatter: (value: number): string => Math.round(value).toString(),
                },

                max: maxYValue,
                min: 0,
                axisBorder: {
                    show: true,
                    color: '#0f104412',
                    offsetX: -3,
                    offsetY: -2,
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                followCursor: true,
                enabled: true,
                intersect: true, // Show tooltip only for the hovered point
                custom: ({ series, seriesIndex, dataPointIndex }) => {
                    const value = series[seriesIndex][dataPointIndex];
                    const timeData = filterFormat?.[dataPointIndex];

                    let filteredProtocols: MetricGraphDataType[] = [];
                    // eslint-disable-next-line react-hooks/rules-of-hooks
                    filteredProtocols = chartData.filter((protocol) => {
                        const protocolValue =
                            protocol.data[dataPointIndex] !== undefined ? protocol.data[dataPointIndex] : 0;

                        return protocolValue === value;
                    });

                    if (selectedMetricIds.length > 0) {
                        tooltipDataRef.current = {
                            value,
                            timeData,
                            metricData: filteredProtocols[0],
                        };
                    } else {
                        tooltipDataRef.current = { value, timeData, metricData: metricGraphData };
                    }

                    const showTime = timeFilter == 'Day' ? '' : timeData;

                    const filteredProtocolsHTML = filteredProtocols
                        ?.map((metric, index, arr) => {
                            const icon = MetricsOptionData?.find((item) => item.name === metric.format)?.icon;
                            const categoryColor = categoryList?.find((item) => item.key === metric.category);

                            return `
                                                        <div style="display: flex; align-items-center; gap: 5px; ${
                                                            index === arr.length - 1 ? 'padding-bottom: 20px' : ''
                                                        }">
              
                                                           <div  style="width : 13px; height : 13px; border-radius:3px; background-color : ${
                                                               categoryColor?.color
                                                           } ">
                                                          </div>
              
                                                           <img  style="width : 13px; height : 13px;" src=${icon} alt=${icon} /> 
                                                         
                                                          <div style="font-weight: 500; font-size: 11px; overflow: hidden; width: 150px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
                                                              ${metric.name}
                                                          </div>
              
              
                                                      </div>
                                                    `;
                        })
                        .join('');

                    // Return the full tooltip HTML including the value and time category
                    return `  
                                                  <div class="trend_graph_tooltip" style=" position: relative;  padding:5px; color: #fff; background-color: rgba(0, 20, 255, 0.75); border-radius: 7px; width: 214px; height: auto;" >
                                                     
                                                  <div class=" metric_scroll_tooltips margin-bottom : 5px" style="  width : 100%; position: relative; overflow-y: auto; ">
                                                     ${filteredProtocolsHTML}
                                                  </div>
                              
                                                          <div style="font-size: 10px; line-height: 15px; display : flex; justify-content: space-between; align-items: center; position : relative;">
                                                              <div > 
                                                                  ${showTime}
                                                              </div>
                                                          
                                                              <div">
                                                                  ${value}
                                                              </div>
                              
                                                              
                                                          <div>
                                                  </div>
                                              `;
                },
            },
            legend: {
                show: false,
            },
            grid: {
                show: true,
                borderColor: '#0f104412',
                strokeDashArray: 0,
                padding: {
                    left: 10,
                    right: 30,
                },
            },
        };

        setChartOptions(options);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartData]);

    return (
        <div
            onClick={() => {
                if (timeFilter !== 'Day') {
                    getGraphData();
                }
            }}
        >
            <Chart options={chartOptions} series={chartValues} type='bar' height={300} />
            <h5 className='chart_title'>
                {chartData[0]?.format ? t(`metricsChart.metricsTypes.${chartData[0]?.format}`) : ''}
            </h5>
        </div>
    );
};

export default MetricBarChart;
