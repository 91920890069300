import { useCallback, useEffect, useRef, useState } from 'react';

import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ReactComponent as OpenDoorIcon } from '../../../../../../src/assets/icons/DoorOpen.svg';
import { ReactComponent as PlusBtn } from '../../../../../assets/icons/EVA_PlusBtn.svg';
import { ReactComponent as PlusBtnHover } from '../../../../../assets/icons/EVA_PlusBtnhover.svg';
import { setClickProtocol, setNewSelectedProtocols } from '../../../../../Redux/SessionsProtocols';
import { RootState, useAppDispatch } from '../../../../../Redux/store';
import { Protocol } from '../../evaAnalytics/components/analyticsChart/types';
import { MetaMarkerItem } from '../types';
import { OptionData } from './ProtocolTypeList';

interface ProtocolListProps {
    metaMarkerData: MetaMarkerItem[];
    setFilteredItems: React.Dispatch<React.SetStateAction<MetaMarkerItem[]>>;
    filteredItems: MetaMarkerItem[];
    setMarkerIndex?: React.Dispatch<React.SetStateAction<number | null>>;
    setProtocolIndex?: React.Dispatch<React.SetStateAction<number | null>>;
    handleDlClick?: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
    open?: boolean;
}

const ProtocolList: React.FC<ProtocolListProps> = ({
    metaMarkerData,
    setFilteredItems,
    filteredItems,
    open,
    setMarkerIndex,
    handleDlClick,
    setProtocolIndex,
}) => {
    const { t } = useTranslation();
    const [searchVal, setSearchVal] = useState<string>('');
    const [activeProtocol, setActiveProtocol] = useState<string[]>([]);
    const [isHoveringLastProtocol, setIsHoveringLastProtocol] = useState(false);

    const [toggle, setToggle] = useState<boolean>(false);
    const [hoverPlus, setHoverPlus] = useState(false);
    const [searchValDraft, setSearchValDraft] = useState<string>('');

    const newProtocolsList = useSelector((state: RootState) => state.SessionsProtocols.NewProtocols);
    const childScreen = useSelector((state: RootState) => state.SessionsProtocols.childScreen);
    const ReportLoading = useSelector((state: RootState) => state.SessionsProtocols.ReportLoading);

    const dispatch = useAppDispatch();

    const filterData = () => {
        if (setMarkerIndex) {
            const filtered = metaMarkerData.filter((item) =>
                item?.metaMarker.toLowerCase().includes(searchValDraft.toLowerCase()),
            );
            setFilteredItems(filtered);
        } else {
            const filtered = metaMarkerData.filter((item) =>
                item?.metaMarker.toLowerCase().includes(searchVal.toLowerCase()),
            );
            setFilteredItems(filtered);
        }
    };
    useEffect(() => {
        filterData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchVal, searchValDraft, metaMarkerData]);

    const searchChange = (value: string) => {
        if (setMarkerIndex) {
            setSearchValDraft(value);
        } else {
            setSearchVal(value);
        }
    };

    useEffect(() => {
        if (newProtocolsList) {
            const filteredNames = newProtocolsList.map((item: Protocol) => item?.name);
            setActiveProtocol(filteredNames);
        }
    }, [newProtocolsList]); // Use `newProtocolsList` only once as a dependency

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [isBottom, setIsBottom] = useState(false);

    useEffect(() => {
        const updateHeightAndScroll = () => {
            if (containerRef.current) {
                const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
                // Add or remove the class based on height comparison
                if (scrollHeight > clientHeight) {
                    setIsBottom(true);
                } else {
                    setIsBottom(false);
                }
                // Handle scroll to bottom detection
                if (scrollTop + clientHeight >= scrollHeight - 5) {
                    setIsBottom(true);
                } else {
                    setIsBottom(false);
                }
            }
        };
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', updateHeightAndScroll);
            updateHeightAndScroll(); // Check on mount if it is scrollable
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', updateHeightAndScroll);
            }
        };
    }, [filteredItems, searchValDraft]);

    useEffect(() => {
        if (activeProtocol && filteredItems) {
            const filteredResults = filteredItems
                .filter((item) => activeProtocol.includes(item.metaMarker))
                .map((item) => {
                    // Check if the metaMarker exists in newProtocolsList
                    const existingProtocol = newProtocolsList?.find((obj) => obj.name === item.metaMarker);

                    return {
                        name: item.metaMarker,
                        protocolType: item.protocolType ?? 'Custom',
                        isManual: existingProtocol ? existingProtocol.isManual : true,
                    };
                });

            dispatch(setNewSelectedProtocols(filteredResults));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeProtocol, filteredItems, newProtocolsList]);

    const toggleProtocol = useCallback(
        (name: string) => {
            setActiveProtocol((prev) => {
                return prev?.includes(name) ? prev?.filter((i) => i !== name) : [...prev, name];
            });
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [setActiveProtocol],
    );
    return (
        <>
            {metaMarkerData.length > 0 && (
                <div className={`${toggle ? 'show_protocol_list' : ''} main_protocol_group`}>
                    <div className='flex  top_protocol'>
                        <div className=' open_btn flex items-center justify-center' onClick={() => setToggle(!toggle)}>
                            <OpenDoorIcon />
                        </div>
                        <div
                            className={`${
                                searchVal.length > 0 || searchValDraft.length > 0 ? 'search_val' : ''
                            } protocol_input_box ml-3`}
                        >
                            <input
                                placeholder={t('search')}
                                type='text'
                                value={setMarkerIndex ? searchValDraft : searchVal}
                                onChange={(e) => searchChange(e.target.value)}
                            />
                        </div>
                    </div>

                    <div className='protocol_box  ' ref={containerRef}>
                        {filteredItems?.length > 0 ? (
                            <>
                                {filteredItems?.map((data: MetaMarkerItem, index: number) => {
                                    const isActive = activeProtocol?.includes(data.metaMarker);

                                    const metaMarkersData = metaMarkerData.map(
                                        (item: MetaMarkerItem, index: number) => {
                                            return {
                                                ...item,
                                                indexNum: index, // Add the indexNum key here
                                            };
                                        },
                                    );
                                    const getIndexNum = metaMarkersData.find(
                                        (item, index) => item.metaMarker === data.metaMarker,
                                    )?.indexNum;

                                    return (
                                        <>
                                            <div
                                                onMouseEnter={() => setIsHoveringLastProtocol(true)}
                                                className={
                                                    index === filteredItems.length - 1 &&
                                                    !isHoveringLastProtocol &&
                                                    !isBottom
                                                        ? 'protocol_last_type'
                                                        : 'protocol_lists'
                                                }
                                            />
                                            <div
                                                className={`main_protocol_content ${
                                                    !handleDlClick && isActive ? 'active_protocol' : ''
                                                }`}
                                                onClick={(e) => {
                                                    if (!isHoveringLastProtocol && typeof getIndexNum === 'number') {
                                                        if (!ReportLoading) {
                                                            toggleProtocol(data.metaMarker);
                                                            setTimeout(() => {
                                                                if (
                                                                    childScreen === 'Report' ||
                                                                    childScreen === 'ViewHistoryReport' ||
                                                                    childScreen === 'History'
                                                                ) {
                                                                    dispatch(setClickProtocol(true));
                                                                }
                                                            }, 500);
                                                        }
                                                    }
                                                }}
                                                onMouseEnter={() =>
                                                    typeof getIndexNum === 'number' &&
                                                    setProtocolIndex &&
                                                    setProtocolIndex(getIndexNum)
                                                }
                                                onMouseLeave={() => {
                                                    if (setProtocolIndex) {
                                                        setProtocolIndex(null);
                                                    }
                                                    setIsHoveringLastProtocol(false);
                                                }}
                                            >
                                                <div
                                                    className='content'
                                                    onClick={(e) => {
                                                        if (isHoveringLastProtocol) {
                                                            noop();
                                                        } else {
                                                            if (setMarkerIndex) {
                                                                setMarkerIndex(data?.indexNum);
                                                            }
                                                            if (handleDlClick) {
                                                                handleDlClick(e, data?.indexNum);
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <span
                                                        className='side_bg'
                                                        style={{ backgroundColor: `${data?.color}` }}
                                                    />

                                                    <img
                                                        src={
                                                            OptionData.find((obj) => obj.name === data.protocolType)
                                                                ?.icon
                                                        }
                                                        alt='winnerCup'
                                                    />
                                                    <p>{data?.metaMarker}</p>
                                                </div>
                                                <div className='protocol_count relative'>
                                                    <div className='flex items-center justify-center'>
                                                        <span>{data?.triggerCount ?? 0}</span>
                                                        <div className='tooltip_box items-center justify-center'>
                                                            <p>{t('triggers')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                            </>
                        ) : (
                            <p className='no_data '> {t('no_protocol_found')} </p>
                        )}
                    </div>
                    {setMarkerIndex && (
                        <div
                            className='eva_plus_btn cursor-pointer'
                            onMouseEnter={() => setHoverPlus(true)}
                            onMouseLeave={() => setHoverPlus(false)}
                            onClick={(e) => {
                                if (handleDlClick) {
                                    handleDlClick(e);
                                }
                            }}
                        >
                            {hoverPlus ? <PlusBtnHover /> : <PlusBtn />}
                        </div>
                    )}
                </div>
            )}
        </>
    );
};

export default ProtocolList;
