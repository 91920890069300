import { useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link, useHistory, useParams } from 'react-router-dom';

import { stripePromise } from '../../..';
import { ReactComponent as DisabledNorthStarIcon } from '../../../../src/assets/images/north-star-disabled.svg';
import { ReactComponent as NorthStarIcon } from '../../../../src/assets/images/north-star.svg';
import { appInsights } from '../../../AppInsights';
import { ReactComponent as BackIcon } from '../../../assets/icons/icon-arrow-left-long.svg';
import { useOrganisationForm } from '../../../hooks/useOrganisation';
import { useSubscription } from '../../../hooks/useSubscription';
import { store, useStore } from '../../../store';
import { ORGANISATION_TIERS, SUBSCRIPTION_BUTTON_LABEL, SUBSCRIPTION_STATUS } from '../../../store/OrganisationStore';
import { CardResponse } from '../../../store/UserStore';
import { SUBSCRIPTION_TYPE } from '../../../utils/constants';
import { useAsyncFetcher } from '../../../utils/useAsyncFetcher';
import PaymentMethodCard from '../../admin/paymentMethods/PaymentMethodCard';
import { Button } from '../../basic/Button.component';
import HorizontalSeparator from '../../basic/HorizontalSeparator';
import { IconAlert } from '../../basic/IconAlert.component';
import { Input } from '../../basic/Input.component';
import { Switch } from '../../basic/Switch.component';
import { useServices } from '../../ServiceProvider';

const ConfirmOrganisationPlan = ({
    upgrade = false,
    goTo,
    refetchSubscription,
}: {
    upgrade?: boolean;
    goTo?: string;
    refetchSubscription?: () => void;
}) => {
    const history = useHistory();
    const { t } = useTranslation();
    const { organisation } = useServices();
    const [cardPayment, setCardPayment] = useState('');
    const [loading, setLoading] = useState(false);
    const [showTaxId, setShowTaxId] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const { user } = useStore();
    const { id: organisationId, type } = useParams<{ id: string; type: string }>();
    const { createOrUpdateSubscription, resumeSubscription, fields, setValue } = useOrganisationForm();
    const { pricings } = useSubscription();
    const [northStarEnabled, setNorthStarEnabled] = useState(false);
    const [nsaCount, setNsaCount] = useState(1);
    const [nsaAmount, setNsaAmount] = useState(39);
    const { data: subscription, refetch } = useQuery(
        ['getSubscription', organisationId],
        async () => {
            try {
                const response = await organisation.getSubscription(organisationId);
                if (response && response.taxId) {
                    setShowTaxId(true);
                    setValue('taxId', response.taxId);
                }
                return response;
            } catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
            }
        },
        { enabled: upgrade },
    );
    const organisationName = store.spaces.currentSelectedWorkSpaceInfo.workspaceName;

    const { data: cards, loading: cardsLoading } = useAsyncFetcher<CardResponse[]>(
        async () => organisation.getPaymentMethods(organisationId),
        [],
    );

    if (subscription && subscription.status !== SUBSCRIPTION_STATUS.CANCELED && !upgrade) {
        history.push(`/organisation/${organisationId}/eva`);
    }

    const handleSubmit = async () => {
        setLoading(true);
        if (user.userData && store.organisation.selectedTier) {
            try {
                setError(null);
                if (subscription?.status === SUBSCRIPTION_STATUS.CANCELED) {
                    await resumeSubscription({
                        payment_method: cardPayment,
                        product_name: store.organisation.selectedTier?.toUpperCase(),
                        type: type,
                        organisationId: organisationId,
                        taxId: fields.taxId.value,
                    });
                } else {
                    await createOrUpdateSubscription({
                        payment_method: cardPayment,
                        product_name: store.organisation.selectedTier?.toUpperCase(),
                        type: type,
                        organisationId: organisationId,
                        taxId: fields.taxId.value,
                    });
                }
                const currentOrganisation = store.organisation.getOrganisationData();
                if (currentOrganisation) {
                    const workspaceInfo = {
                        workspaceBanner: '',
                        workspaceIcon: currentOrganisation.organisationName[0],
                        workspaceName: currentOrganisation.organisationName,
                        workspaceColor: '',
                        workspaceLogo: currentOrganisation.logo ? currentOrganisation.logo : '',
                        isOrganisation: true,
                    };
                    store.spaces.setCurrentSelectedWorkSpaceInfo(workspaceInfo);
                    // Clearing the local storage variable when the user has successfully subscribed to a plan
                    localStorage.removeItem('organisationName');
                    localStorage.removeItem('selectedTier');
                }
                localStorage.setItem('spaceId', organisationId);
                setTimeout(() => {
                    setLoading(false);
                    refetch();
                    if (refetchSubscription) refetchSubscription();
                    if (upgrade) history.push(`/organisation/${organisationId}/billing`);
                    else history.push(`/organisation/${organisationId}/eva`);
                }, 2000);
            } catch (err) {
                if (err instanceof Error) {
                    setError(err.message);
                    appInsights.trackException({ error: err });
                } else {
                    setError(t('error.paymentFailed'));
                    appInsights.trackException({ error: new Error('Organisation plan subscription failed.') });
                }
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        if (northStarEnabled) {
            if (type === SUBSCRIPTION_TYPE.YEARLY) {
                setNsaAmount(nsaCount * 39);
            } else setNsaAmount(nsaCount * 49);
        } else {
            setNsaAmount(0);
        }
    }, [northStarEnabled, nsaCount, type]);

    if (!pricings)
        return (
            <div className='w-full h-screen flex justify-center items-center'>
                <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />
            </div>
        );

    if (loading)
        return (
            <div className='flex-grow flex flex-col justify-center items-center pt-20 text-darker text-opacity-75 mt-24'>
                <h3 className='text-center font-semibold mb-2 text-base'>{t('billing.processingPayment')}</h3>
                <p className='text-center text-sm mb-6'>{t('billing.paymentConfirmation')}</p>
                <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />
            </div>
        );

    const subscriptionMonthlyPrice =
        store.organisation.selectedTier === ORGANISATION_TIERS.SHIELD
            ? pricings.shieldMonthlyPricing.price
            : pricings.oversightMonthlyPricing.price;
    const subscriptionYearlyPrice =
        store.organisation.selectedTier === ORGANISATION_TIERS.SHIELD
            ? pricings.shieldYearlyPricing.price
            : pricings.oversightYearlyPricing.price;

    return (
        <div className='w-full'>
            <button
                onClick={() => history.goBack()}
                className='absolute top-10 mt-10 right-6 flex items-center space-x-2 ml-auto h-8'
            >
                <BackIcon />
                <p className='text-sm text-darker text-opacity-75'>{t('btnNames.back')}</p>
            </button>
            <div className='w-full mx-auto flex flex-col text-sm text-darker text-opacity-75 max-w-5xl'>
                <p className='mb-6 mt-10 text-center capitalize font-medium'>
                    {t('btnNames.confirm')} Tier {store.organisation.selectedTier}
                </p>
                <div className='flex flex-col p-4 bg-primary-darker bg-opacity-2 rounded-2xl shadow-consult border border-blue-dark border-opacity-10'>
                    <div className='flex justify-between items-center'>
                        <div>
                            <p className='font-semibold text-sm capitalize text-darker text-opacity-75'>
                                {type === SUBSCRIPTION_TYPE.YEARLY
                                    ? t('subscription_tier.annual')
                                    : t('subscription_tier.monthly')}{' '}
                                Tier <span className='uppercase'>{store.organisation.selectedTier}</span>
                            </p>
                            <p className='mt-1 text-darker text-xs font-normal'>
                                {t('navbar.organisation')}:{' '}
                                <span className='font-bold text-darker'>{organisationName}</span>
                            </p>
                        </div>
                        <div>
                            <div className='flex justify-end'>
                                <p className='font-semibold text-base text-darker text-opacity-75'>
                                    £
                                    {type === SUBSCRIPTION_TYPE.YEARLY
                                        ? Number(subscriptionMonthlyPrice) * 12
                                        : subscriptionMonthlyPrice}
                                </p>
                            </div>
                            {type === SUBSCRIPTION_TYPE.YEARLY && (
                                <div className='flex items-center space-x-1'>
                                    <div className='text-xxs bg-warning-100 p-1 rounded-md font-medium'>
                                        <p className='text-orange'>20% off</p>
                                    </div>
                                    <p className='font-semibold text-sm text-darker text-opacity-75'>
                                        -£{Number(subscriptionMonthlyPrice) * 12 * 0.2}
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* north star add on */}
                    <HorizontalSeparator className='mt-4' />
                    {/* {northStarEnabled ? (
                        <div className='w-full py-4'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center space-x-2'>
                                    <div>
                                        <NorthStarIcon className='w-10 h-10' />
                                    </div>
                                    <p className='font-semibold text-darker text-opacity-75'>North Star-Alignment</p>
                                </div>
                                <div>
                                    <Switch checked={northStarEnabled} onChange={setNorthStarEnabled} />
                                </div>
                            </div>
                            <div className='flex justify-between mt-4 items-center'>
                                <div className='flex space-x-4 items-center text-darker text-opacity-75'>
                                    <p className='text-xs'>{t('nsa.no-of-access')}</p>
                                    <button
                                        className='flex items-center justify-center bg-primary-darker bg-opacity-5 rounded-full w-6 h-6 p-1'
                                        disabled={nsaCount === 1}
                                        onClick={() => setNsaCount(nsaCount - 1)}
                                    >
                                        <p className='font-medium'>-</p>
                                    </button>
                                    <p>{nsaCount}</p>
                                    <button
                                        className='flex items-center justify-center bg-primary-darker bg-opacity-5 rounded-full w-6 h-6 p-1'
                                        onClick={() => setNsaCount(nsaCount + 1)}
                                    >
                                        <p className='font-medium'>+</p>
                                    </button>
                                </div>
                                <div>
                                    <p className='font-semibold text-base text-darker text-opacity-75'>£{nsaAmount}</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className='w-full py-4'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center space-x-2'>
                                    <div>
                                        <DisabledNorthStarIcon className='w-10 h-10' />
                                    </div>
                                    <p className='font-semibold text-darker text-opacity-50'>North Star-Alignment</p>
                                </div>
                                <div>
                                    <Switch checked={northStarEnabled} onChange={setNorthStarEnabled} />
                                </div>
                            </div>
                        </div>
                    )}
                    <HorizontalSeparator /> */}

                    <button
                        className='w-full mt-4 flex text-blue-ryb text-xs font-medium'
                        onClick={() => setShowTaxId(!showTaxId)}
                    >
                        <p>{t('billing.addTax')}</p>
                    </button>
                    {showTaxId && (
                        <div className='w-60 mt-2'>
                            <Input placeholder='Tax ID' {...fields.taxId} maxLength={11} />
                        </div>
                    )}
                    <div className='w-full flex justify-end text-xs text-darker text-opacity-50 mt-5'>
                        <div>
                            {type === SUBSCRIPTION_TYPE.YEARLY ? (
                                <p className='w-full flex justify-end font-semibold text-xl text-darker text-opacity-75 mr-5'>
                                    £{subscriptionYearlyPrice + nsaAmount}
                                </p>
                            ) : (
                                <p className='w-full flex justify-end font-semibold text-xl text-darker text-opacity-75 mr-5'>
                                    £{subscriptionMonthlyPrice + nsaAmount}
                                </p>
                            )}
                            <p>{t('btnNames.cancelAtAnyTime')}</p>
                        </div>
                    </div>
                </div>
                {cardsLoading && (
                    <div className='flex items-center justify-center'>
                        {!cards && <CircularProgress sx={{ color: '#373BBA', width: '32px', height: '32px' }} />}
                    </div>
                )}
                {cards && cards.length == 0 ? (
                    <div className='my-5 font-medium text-blue-ryb text-xs'>
                        <Link to={goTo ? goTo : `/create-organisation/${organisationId}/add-payment`}>
                            + {t('billing.addPayment')}
                        </Link>
                    </div>
                ) : (
                    cards &&
                    cards.length && (
                        <div className='flex flex-col mt-6'>
                            <p className='font-medium text-sm mb-4 text-darker text-opacity-75'>
                                {t('billing.choosePayment')}
                            </p>
                            <Elements stripe={stripePromise}>
                                <div className='w-full mb-6 space-y-4'>
                                    {cards?.map(({ card, isDefault, isExpired }, index) => (
                                        <PaymentMethodCard
                                            key={index}
                                            card={card}
                                            edit={false}
                                            radioButton
                                            checked={cardPayment == card.id}
                                            setChecked={() => setCardPayment(card.id)}
                                            isDefault={isDefault}
                                            isExpired={isExpired}
                                            navigation={`/create-organisation/${organisationId}/edit-payment`}
                                        />
                                    ))}
                                </div>
                            </Elements>
                            <Link
                                to={goTo ? goTo : `/create-organisation/${organisationId}/add-payment`}
                                className='mb-7 text-sm text-bberry-blue font-medium'
                            >
                                {t('billing.change')}
                            </Link>
                        </div>
                    )
                )}
                {error && (
                    <div>
                        <IconAlert message={error} />
                    </div>
                )}
                <Button
                    onClick={handleSubmit}
                    variant='blueRYB'
                    size='oval'
                    className='flex space-x-2 w-full mt-2 py-5 h-10 items-center'
                    disabled={cardPayment == ''}
                >
                    {!upgrade
                        ? SUBSCRIPTION_BUTTON_LABEL.START_TRIAL
                        : subscription?.status === SUBSCRIPTION_STATUS.CANCELED
                        ? t('SUBSCRIPTION_BUTTON_LABEL.SUBSCRIBE')
                        : subscription.productName === ORGANISATION_TIERS.OVERSIGHT &&
                          store.organisation.selectedTier === ORGANISATION_TIERS.SHIELD
                        ? t('SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE')
                        : subscription.type === SUBSCRIPTION_TYPE.YEARLY &&
                          subscription.productName === ORGANISATION_TIERS.SHIELD &&
                          store.organisation.selectedTier === ORGANISATION_TIERS.SHIELD
                        ? t('SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE')
                        : subscription.type === SUBSCRIPTION_TYPE.YEARLY &&
                          subscription.productName === ORGANISATION_TIERS.OVERSIGHT &&
                          store.organisation.selectedTier === ORGANISATION_TIERS.OVERSIGHT
                        ? t('SUBSCRIPTION_BUTTON_LABEL.DOWNGRADE')
                        : t('SUBSCRIPTION_BUTTON_LABEL.UPGRADE')}
                </Button>
            </div>
        </div>
    );
};

export default ConfirmOrganisationPlan;
