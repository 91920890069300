import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import '../Trends/Metrics.css';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Tooltip as Tooltip } from 'react-tooltip';

import { appInsights } from '../../../../../AppInsights';
import { RootState, useAppDispatch } from '../../../../../Redux/store';
import {
    setChartData,
    setMetricLoading,
    setSelectedIds,
    setSelectedMetricGraphData,
    setSelectedMetricIds,
    setSelectMetricProtocol,
} from '../../../../../Redux/TrendMetric';
import { MetricService } from '../../../../../services/metric/metric.service';
import { IconAlert } from '../../../../basic/IconAlert.component';
import { ReactComponent as DownArrow } from '../.././../../../../src/assets/icons/icon-down-arrow.svg';
import { ReactComponent as SearchIcon } from '../.././../../../../src/assets/icons/serachIocn.svg';
import { ReactComponent as SortIcon } from '../.././../../../../src/assets/icons/SortFilterIcon.svg';
import { CalculationType, categoryList, FormatList, MetricTypes } from '../../eva/components/metrics/metricsType';
import { MetricsOptionData } from '../../eva/components/MetricsConfig';
import { OptionData } from '../../eva/components/ProtocolTypeList';
import { ChartType } from '../../eva/types';
import { ReactComponent as InfoIcon } from './../../../../../assets/icons/info_Iocn.svg';
import { ReactComponent as MetricsStar } from './../../../../../assets/icons/Metric_Star.svg';
import MetricBarChart from './MetricChart/MetricBarChart';
import MetricGaugeChart from './MetricChart/MetricGaugeChart';
import MetricHeatMapChart from './MetricChart/MetricHeatMapChart';
import MetricLineChart from './MetricChart/MetricLineChart';

interface MetricsProps {
    evaId: string;
    timeFilter: string;
    setTimeFilter: Dispatch<SetStateAction<string>>;
    setBackTimeFilter: Dispatch<SetStateAction<string[]>>;
    metricDrilledDatePayload: string | null;
    backTimeFilter: string[];
}

const Metrics: React.FC<MetricsProps> = ({
    evaId,
    timeFilter,
    setTimeFilter,
    setBackTimeFilter,
    metricDrilledDatePayload,
    backTimeFilter,
}) => {
    const { t } = useTranslation();
    const [search, setSearch] = useState('');
    const [showSearch, setShowSearch] = useState(false);
    const timeZoneOffset = moment().utcOffset().toString();

    const [showFilter, setShowFilter] = useState(false);
    const [metricFormat, setMetricFormat] = useState<string[]>([]);
    const dispatch = useAppDispatch();

    const [selectedFormat, setSelectedFormat] = useState<string>('');
    const [showError, setShowError] = useState(false);

    const selectedIds = useSelector((state: RootState) => state.TrendMetric.selectedIds);
    const selectedMetricIds = useSelector((state: RootState) => state.TrendMetric.selectedMetricIds);
    const selectedProtocols = useSelector((state: RootState) => state.TrendMetric.selectedProtocols);
    const selectedMetricGraphData = useSelector((state: RootState) => state.TrendMetric.selectedMetricGraphData);
    const { metricDrilledDate } = selectedMetricGraphData;

    const metricService = new MetricService();

    const {
        isLoading,
        isFetching,
        data: MetricDataList,
    } = useQuery(
        ['getMetricData', evaId, metricFormat, search],
        async () => {
            try {
                if (evaId.length > 0) {
                    const MetricDataList = await metricService.getMetricData(evaId, metricFormat, search);
                    return MetricDataList;
                }
            } catch (e) {
                return [];
            }
        },
        { enabled: true },
    );

    useEffect(() => {
        dispatch(setMetricLoading(isLoading || isFetching));
    }, [dispatch, isFetching, isLoading]);

    const {
        isLoading: isMetricLoading,
        isFetching: isMetricFetching,
        data: metricTrendData,
        refetch,
    } = useQuery(
        ['getMetricsTrendAnalysis', timeFilter, timeZoneOffset, selectedMetricIds],
        async () => {
            try {
                if (evaId.length > 0) {
                    const metricTrends = await metricService.getMetricsTrendAnalysis(
                        evaId,
                        timeFilter,
                        timeZoneOffset,
                        metricDrilledDatePayload,
                        selectedMetricIds,
                    );

                    if (metricTrends) {
                        dispatch(
                            setSelectedMetricGraphData({
                                metricDrilledDate: null,
                            }),
                        );
                    }

                    return metricTrends;
                }
            } catch (e) {
                console.error(e);
                if (e instanceof Error) {
                    appInsights.trackException({ error: e });
                }
                throw e;
            }
        },
        {
            enabled:
                selectedMetricIds.length > 0 &&
                backTimeFilter.length === 0 &&
                (metricDrilledDate === '' || metricDrilledDate === null)
                    ? true
                    : backTimeFilter.length > 0 && selectedMetricIds.length > 0
                    ? true
                    : false,
        },
    );

    const toggleFilter = (filterName: string): void => {
        setMetricFormat((prev) => {
            if (prev.includes(filterName)) {
                return prev.filter((name) => name !== filterName); // Remove if already selected
            } else {
                return [...prev, filterName]; // Add if not selected
            }
        });
    };

    const chartType = MetricsOptionData.find((obj) => obj.name === selectedFormat)?.chartType;

    const toggleMetric = (id: string, format: string, type: string) => {
        // Get the current selected IDs from the Redux store
        setTimeout(() => {
            setShowError(false);
        }, 2000);
        // Compute the new selected IDs based on the current selection and the incoming id and format
        const newSelectedIds = (prevIds: string[]) => {
            if (prevIds.includes(id)) {
                // If the ID is already selected, remove it from the selection
                return prevIds.filter((existingId) => existingId !== id);
            } else {
                if (prevIds.length === 0) {
                    return [...prevIds, id];
                } else {
                    const allSameFormat = prevIds.every((selectedId) => {
                        const selectedMetric = MetricDataList?.Metrics.find(
                            (metric: { id: string }) => metric.id === selectedId,
                        );
                        return selectedMetric ? selectedMetric.format === format : false;
                    });

                    if (allSameFormat) {
                        if (format === FormatList.RiskLevel && type === CalculationType.Mode) {
                            return [id];
                        }
                        return [...prevIds, id];
                    } else {
                        setShowError(true);
                        return prevIds; // Return the previous IDs without modification
                    }
                }
            }
        };

        // Dispatch the updated selected IDs to the Redux store
        dispatch(setSelectedIds(newSelectedIds(selectedIds)));
        dispatch(setSelectedMetricIds(newSelectedIds(selectedIds)));
    };

    const dateRange = metricTrendData?.dateRanges;

    const handleMetricsUpdate = useCallback(() => {
        if (selectedIds) {
            const newFilteredMetrics = MetricDataList?.Metrics?.filter((obj: { id: string }) =>
                selectedIds.includes(obj.id),
            );

            const selectedFilteredMetrics = metricTrendData?.metricResult?.filter((obj: { metricId: string }) =>
                selectedMetricIds.includes(obj.metricId),
            );

            if (selectedFilteredMetrics) {
                const chartData = selectedFilteredMetrics?.map(
                    (item: { name: string; metricsChart: []; metricId: string; category: string; format: string }) => ({
                        name: item.name,
                        data: item.metricsChart,
                        id: item.metricId,
                        category: item.category,
                        format: item.format,
                    }),
                );
                dispatch(setChartData(chartData));
            }

            if (newFilteredMetrics) {
                const allProtocolNames = newFilteredMetrics
                    .flatMap((item: { protocolNames: { name: string; protocolType: string }[] }) =>
                        item.protocolNames.map((protocol) => protocol.name),
                    )
                    // Filter out duplicate protocol names by checking the index of each value
                    .filter((value: string, index: number, self: string[]) => self.indexOf(value) === index);

                dispatch(setSelectMetricProtocol(allProtocolNames));
            }

            if (newFilteredMetrics) {
                const uniqueFormats = new Set(
                    newFilteredMetrics.flatMap((item: { format: string | string[] }) =>
                        // If format is an array, spread it; otherwise, wrap it in an array
                        Array.isArray(item.format) ? item.format : [item.format],
                    ),
                );

                // Get the first unique format value from the Set
                const firstFormat = uniqueFormats.values().next().value as string | undefined;
                if (firstFormat) {
                    setSelectedFormat(firstFormat);
                }
            }
        }
    }, [selectedIds, MetricDataList, metricTrendData, selectedMetricIds, dispatch]);

    useEffect(() => {
        handleMetricsUpdate();
    }, [handleMetricsUpdate]);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (showError) {
            timer = setTimeout(() => {
                setShowError(false);
            }, 5000);
        }
        return () => {
            clearTimeout(timer);
        };
    }, [showError]);

    return (
        <div className='trends_box metric relative'>
            {isFetching ||
                (isMetricFetching && (
                    <div className='trend_loading_box'>
                        <div id='loading' />
                    </div>
                ))}
            <div className='trends_protocol relative'>
                <div className='trends_head mb-5 pb-2 flex justify-between items-center'>
                    <h2 className='flex items-center gap-1'>
                        {t('metricsChart.metrics')}
                        <InfoIcon className='ml-2 cursor-pointer' />{' '}
                        <div className='navi_info_box'>{t('metricsChart.triggeringFrequency')}</div>
                    </h2>
                    <div className='trend_top_box  w-full flex  items-center flex-wrap justify-end'>
                        <div
                            className={` search_box d-flex pr-3 items-center relative ${
                                showSearch ? 'show_search' : ''
                            }`}
                        >
                            <div className='searchIocn' onClick={() => setShowSearch(!showSearch)}>
                                <SearchIcon />
                            </div>
                            <input type='text' onChange={(e) => setSearch(e.target.value)} placeholder={t('search')} />
                        </div>

                        <div
                            className={`metric_filter relative flex items-center  cursor-pointer  ${
                                showFilter ? 'metric_filter_active' : ''
                            }`}
                        >
                            <div
                                className={`flex items-center metric_filter_box  justify-around`}
                                onClick={() => setShowFilter(!showFilter)}
                            >
                                <SortIcon /> <span> {t('metricsChart.filter')} </span> <DownArrow />
                            </div>
                            {showFilter && (
                                <div className='filter_item_box absolute'>
                                    {MetricsOptionData?.map((obj, index) => {
                                        return (
                                            <button
                                                key={index}
                                                className={`flex justify-center gap-2 items-center ${
                                                    metricFormat.includes(obj?.name) ? 'selected' : ''
                                                }`}
                                                onClick={() => toggleFilter(obj?.name)}
                                            >
                                                <img src={obj.icon} alt={obj.icon} />{' '}
                                                {t(`metricsChart.metricsTypes.${obj.name}`)}
                                            </button>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='metric_indicators_scroll'>
                    {MetricDataList?.Metrics.length > 0 ? (
                        <div className='metric_indicators'>
                            <>
                                {MetricDataList?.Metrics?.map((obj: MetricTypes, index: number) => {
                                    const icon = MetricsOptionData?.find((item) => item.name === obj.format)?.icon;
                                    const data = categoryList?.find((item) => item.key === obj.category);

                                    const isHighlighted = obj.protocolNames.some((protocol) =>
                                        selectedProtocols?.includes(protocol.name),
                                    );

                                    return (
                                        <div
                                            key={index}
                                            onClick={() => {
                                                toggleMetric(obj.id, obj.format, obj.type);
                                                setBackTimeFilter([]);
                                            }}
                                            data-tooltip-id={obj.id} // Tooltip identifier
                                            className={`metric_indicators_box relative flex justify-between items-center ${
                                                selectedIds.includes(obj.id) ? `active` : ''
                                            }`}
                                            style={{
                                                borderColor:
                                                    isHighlighted || selectedIds.includes(obj.id)
                                                        ? data?.borderColor
                                                        : '',
                                                backgroundColor:
                                                    isHighlighted || selectedIds.includes(obj.id) ? data?.bgColor : '',
                                            }}
                                        >
                                            <div className='metric_box flex items-center gap-2'>
                                                <div className='indicators' style={{ backgroundColor: data?.color }} />
                                                <button className='flex justify-center gap-2 items-center'>
                                                    <img src={icon} alt={icon} /> {obj.name}
                                                </button>
                                            </div>
                                            {obj.isFavorite && (
                                                <div className='metricsStar'>
                                                    <MetricsStar />
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}

                                {MetricDataList?.Metrics.map(
                                    (obj: MetricTypes, index: number) =>
                                        obj?.protocolNames?.length > 0 && (
                                            <Tooltip
                                                key={index}
                                                id={obj.id}
                                                offset={5}
                                                clickable={true}
                                                place='top-start'
                                                className='tooltip-box metric_scroll_tooltips '
                                            >
                                                {obj.protocolNames.map((item, key) => {
                                                    const icon = OptionData.find(
                                                        (data) => data.name === item.protocolType,
                                                    )?.icon;
                                                    return (
                                                        <div className='flex items-center gap-2' key={key}>
                                                            {icon && <img src={icon} alt={item.protocolType} />}{' '}
                                                            <p>{item.name}</p>
                                                        </div>
                                                    );
                                                })}
                                            </Tooltip>
                                        ),
                                )}
                            </>
                        </div>
                    ) : (
                        <div className='text-center'>
                            <p className='no_data_found capitalize'>{MetricDataList?.msg}</p>
                        </div>
                    )}
                </div>

                {MetricDataList?.Metrics.length > 0 && selectedIds.length > 0 && (
                    <div className='metric_chart'>
                        {chartType === ChartType.LineChart && (
                            <MetricLineChart
                                timeFilter={timeFilter}
                                dateRange={dateRange}
                                isLoading={isMetricLoading}
                                isFetching={isMetricFetching}
                                setTimeFilter={setTimeFilter}
                                setBackTimeFilter={setBackTimeFilter}
                            />
                        )}
                        {chartType === ChartType.BarChart && (
                            <MetricBarChart
                                timeFilter={timeFilter}
                                dateRange={dateRange}
                                isLoading={isMetricLoading}
                                isFetching={isMetricFetching}
                                setTimeFilter={setTimeFilter}
                                setBackTimeFilter={setBackTimeFilter}
                            />
                        )}
                        {chartType === ChartType.GaugeChart && (
                            <MetricGaugeChart isLoading={isMetricLoading} isFetching={isMetricFetching} />
                        )}
                        {chartType === ChartType.HeatMap && <MetricHeatMapChart />}
                        {selectedFormat === 'Name' && (
                            <MetricLineChart
                                timeFilter={timeFilter}
                                dateRange={dateRange}
                                isLoading={isMetricLoading}
                                isFetching={isMetricFetching}
                                setTimeFilter={setTimeFilter}
                                setBackTimeFilter={setBackTimeFilter}
                            />
                        )}
                    </div>
                )}
            </div>
            {showError && (
                <IconAlert
                    type='warning'
                    message={t('metricsChart.metricFormatValidation')}
                    className='mb-4  error_box absolute z-20 '
                />
            )}
        </div>
    );
};

export default Metrics;
