import React, { useEffect, useState } from 'react';

import { XIcon } from '@heroicons/react/solid';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { useTour } from '@reactour/tour';
import { noop } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { Link } from 'react-router-dom';

import { useSubscriptionDetail } from '../../../../../contexts/subscription.context';
import { useSettings } from '../../../../../hooks/useSettings';
import { EvaService } from '../../../../../services/eva/eva.service';
import { store } from '../../../../../store';
import { getDocumentUrl } from '../../../../../utils/getDocumentUrl';
import { Button } from '../../../../basic/Button.component';
import { StarIcon } from '../../../../basic/StarIcon.component';
import { Loading } from '../../../../loading';
import {
    EvaCreationMethod,
    EvaType,
    MetaMarkerData,
    MetaMarkerItem,
    SubScriptionType,
    TemplateBlueprint,
    TemplateCategory,
} from '../types';
import { getRgbColorCode } from './ColorCode';
import { ReactComponent as ArrowIconDisable } from './img/icon-arrow-left-disable.svg';
import { ReactComponent as ArrowIconLinear } from './img/icon-arrow-left-Linear.svg';
import { ReactComponent as ArrowIcon } from './img/icon-arrow-left.svg';
import { ReactComponent as OrbitIcon } from './img/orbit.svg';

const useStyles = makeStyles(() => ({
    headerTitle: {
        maxWidth: 630,
        wordBreak: 'break-all',
        '@media (max-width: 1200px)': {
            maxWidth: '40%',
        },
    },
}));

interface EvaTemplateProps {
    closeModal?: () => void;
    handleChangeSlide: () => void;
    handleMetaMarker: (data: string) => void;
    isLoading: boolean;
    handleSubmit: (saveType: EvaType, data?: MetaMarkerData) => Promise<void>;
    organisationId: string;
}

export const EvaTemplate: React.FC<EvaTemplateProps> = ({
    closeModal,
    handleChangeSlide,
    handleMetaMarker,
    isLoading,
    handleSubmit,
    organisationId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const evaService = new EvaService();
    const [activeTab, setActiveTab] = useState('all');
    const { setIsOpen, setCurrentStep } = useTour();
    const [bluePrintIndex, setBluePrintIndex] = useState(-1);
    const [sublist, setSublist] = useState(false);
    const onboardingTour = store.user.userData?.onboardingTour;
    const { updateOnboardingTour } = useSettings();

    const subscription = useSubscriptionDetail();

    // Categories Data
    const TemplatesCategories = async () => {
        const response = await evaService.getTemplatesCategories();
        response.unshift({ id: 'all', name: 'All Categories' }); // Add the default object to the beginning of the array
        setTimeout(() => {
            if (onboardingTour?.startTour && !onboardingTour.evaTemplate) {
                setCurrentStep(3);
                setIsOpen(true);
                updateOnboardingTour(true, 'evaTemplate');
            }
        }, 100);
        return response;
    };

    const { data: templateCategoriesData, isLoading: isTemplateLoading } = useQuery(
        'evaTemplates',
        TemplatesCategories,
    );

    const isShieldSubscription = subscription?.productName === SubScriptionType.SHIELD;

    // Blueprints Categories Data
    const { data: templateBlueprintsData, isLoading: isTemplateBlueprintsDataLoading } = useQuery(
        ['evaTemplates Categories', activeTab], // Unique query key including activeTab to trigger refetch when it changes
        () => evaService.getBlueprintsByCategory(organisationId, activeTab),
    );

    const handleBluePrintEvaTemplate = async (data: TemplateCategory) => {
        setIsOpen(false);
        const response = await evaService.getBlueprintByCategoryIdAndBlueprintId(organisationId, data.id);
        const payload = {
            title: response.data.title,
            description: response.data.description,
            metaMarkers: response.data.metaMarkers.map((marker: MetaMarkerItem) => ({
                ...marker,
                color: getRgbColorCode(marker.nuances.map((ob) => ob.threshold)),
            })),
            saveType: EvaType.draft,
            isTestMode: response.data.isTestMode,
            blueprintJson: '',
            gradients: response.data.gradients,
            templateId: data.id,
            creationMethod: EvaCreationMethod.Template,
        };
        handleSubmit(EvaType.draft, payload);
        handleChangeSlide();
    };
    const handleBluePrintEvaToggle = async (index: number) => {
        if (index == bluePrintIndex) {
            setSublist(!sublist);
        } else {
            setSublist(true);
        }
        setBluePrintIndex(index);
    };

    if (isTemplateLoading || isLoading) {
        return <Loading color='white' />;
    }

    let blueprintsData = Array.isArray(templateBlueprintsData) ? templateBlueprintsData : [];
    if (subscription?.productName === SubScriptionType.SHIELD) {
        blueprintsData = Array.isArray(templateBlueprintsData)
            ? templateBlueprintsData.filter((ob: TemplateBlueprint) => ob.isShieldSubscription)
            : [];
    }

    return (
        <React.Fragment>
            <div className='buttons-eva flex justify-between h-16 mt-5 mb-5'>
                <div className={`absolute left-0 right-0 z-[-1] mx-auto ${classes.headerTitle}`}>
                    <h6 className='template_eva_heading'>{t('select_template')}</h6>
                    <p className='template_eva_sub_heading'>{t('blueprint_options')}</p>
                </div>
                <div className='relative flex w-full h-fit justify-end'>
                    <div className='ml-4 h-8 eva-button-vertical-divider' />
                    <Button
                        style={{ height: 32 }}
                        variant='outlinedCancel'
                        size='small'
                        className='bg-transparent m-0 rounded-none'
                        onClick={closeModal}
                    >
                        <XIcon width={20} height={20} fontSize={20} className='eva-close-icon' />
                    </Button>
                </div>
            </div>
            <Box className='eva-listing-slider mx-auto'>
                <Grid container spacing={1} className='' data-tour='template-menu'>
                    <Grid className='eva-listing' item lg={4} md={4} sm={4} style={{ paddingRight: 15 }}>
                        <div className='list_scrolling'>
                            <div className='listing-top-box'>
                                <div className='p-4'>
                                    <div
                                        className='cursor-pointer flex items-center justify-between'
                                        onClick={() => handleMetaMarker('blank')}
                                    >
                                        <div className='flex items-center start_blank'>
                                            <div className='relative dots icon-box rounded-5 w-6 h-6 flex items-center justify-center mr-2' />
                                            <p className='font-medium text-white text-xs'>{t('startBlank')}</p>
                                        </div>
                                        <div>
                                            <ArrowIcon />
                                        </div>
                                    </div>
                                </div>
                                <div className='p-4 pt-0'>
                                    <div
                                        className={`cursor-pointer flex items-center justify-between`}
                                        onClick={
                                            isShieldSubscription
                                                ? () => {
                                                      noop();
                                                  }
                                                : () => handleMetaMarker('cloud')
                                        }
                                    >
                                        <div className='flex items-center kickstart'>
                                            {isShieldSubscription ? (
                                                <Link to={`/organisation/${organisationId}/change-tier`}>
                                                    {' '}
                                                    <StarIcon isHover={true} />{' '}
                                                </Link>
                                            ) : (
                                                <div className='icon-box rounded-5 w-6 h-6 flex items-center justify-center mr-2'>
                                                    <OrbitIcon />
                                                </div>
                                            )}

                                            <p className={`${isShieldSubscription ? 'disabled' : ''}`}>
                                                {t('kickStart')}
                                            </p>
                                        </div>
                                        <div>{isShieldSubscription ? <ArrowIconDisable /> : <ArrowIcon />}</div>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className='py-4 px-6'>
                                <h3 className='template_text'>{t('Templates')}</h3>
                            </div>
                           
                           <div> 
                            <ul className='eva-listing-tab px-2'>
                                {templateCategoriesData.map((category: TemplateCategory, index: number) => (
                                    <li
                                        key={index}
                                        className={`capitalize rounded-5   mb-2 cursor-pointer ${
                                            activeTab === category.id ? 'active' : ''
                                        }`}
                                    >
                                        {isShieldSubscription && category.id !== 'all' && (
                                            <Link to={`/organisation/${organisationId}/change-tier`}>
                                                {/* <AiFillPoundCircle /> */}
                                                <StarIcon isHover={true} />
                                            </Link>
                                        )}
                                        <span
                                            onClick={() => setActiveTab(category.id)}
                                            className={`${
                                                isShieldSubscription && category.id !== 'all' ? 'disabled' : ''
                                            } py-1.5 px-4`}
                                        >
                                            {t(`templateCategories.${category.name}`)}
                                        </span>
                                    </li>
                                ))}
                            </ul></div>
                        </div>
                    </Grid>
                    <Grid className='h-inherit padding-0' item lg={8} md={8} sm={8}>
                        <div className='eva-listing-details relative overflow-y-auto h-inherit'>
                            {isTemplateBlueprintsDataLoading ? (
                                <Loading color='white' />
                            ) : Array.isArray(blueprintsData) && blueprintsData?.length === 0 ? (
                                <div className='details-blue-box p-4 mb-4 border'>
                                    <h6 className='text-xs font-medium text-center'>{t('no_data_found')}</h6>
                                </div>
                            ) : (
                                templateBlueprintsData.map((temp: TemplateBlueprint, index: number) => {
                                    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                                    const blueprint =
                                        sublist && bluePrintIndex == index && temp.blueprintJSON
                                            ? JSON.parse(temp.blueprintJSON)
                                            : null;
                                    const isActive = isShieldSubscription && temp.isShieldSubscription === false;

                                    return (
                                        <div
                                            key={index}
                                            className={` ${
                                                isActive ? 'disable' : ''
                                            } details-blue-box p-4 mb-4 border cursor-pointer category_content`}
                                            onClick={
                                                isActive
                                                    ? () => noop()
                                                    : (event) =>
                                                          handleBluePrintEvaToggle(index) || event.stopPropagation()
                                            }
                                        >
                                            {isActive && (
                                                <div className='category_star'>
                                                    <Link to={`/organisation/${organisationId}/change-tier`}>
                                                        {' '}
                                                        <StarIcon isHover={true} />
                                                    </Link>
                                                </div>
                                            )}

                                            <h6 className='text-xs font-medium blue_top_heading  text-center'>
                                                {temp.name}
                                            </h6>
                                            <p className='text-small text-center blue_top_detail mt-1 mb-2'>
                                                {temp.description}
                                            </p>

                                            <div className='relative'>
                                                <img
                                                    src={
                                                        isActive
                                                            ? ''
                                                            : temp?.evaBlueprintImage &&
                                                              getDocumentUrl(+temp?.evaBlueprintImage)
                                                    }
                                                    className='block m-auto object-center object-cover h-32'
                                                />
                                                {sublist && bluePrintIndex == index && (
                                                    <div
                                                        className='flex items-center next_btn absolute  '
                                                        onClick={(event) => {
                                                            if (templateBlueprintsData?.length > 0) {
                                                                handleBluePrintEvaTemplate(
                                                                    templateBlueprintsData[index],
                                                                );
                                                                event.stopPropagation();
                                                            }
                                                        }}
                                                    >
                                                        <p>{t('Pick')}</p>
                                                        <ArrowIconLinear />
                                                    </div>
                                                )}
                                            </div>

                                            {sublist && bluePrintIndex == index && (
                                                <>
                                                    <p className='text-small protocol_text mt-2 mb-2'>{`${t('protocols')}:`}</p>

                                                    {typeof blueprint === 'object' &&
                                                        blueprint !== null &&
                                                        Array.isArray(blueprint?.blueprint) &&
                                                        blueprint?.blueprint?.map((item: string, index: number) => {
                                                            if (index % 2 === 0) {
                                                                return (
                                                                    <>
                                                                        <div className='flex flex-row '>
                                                                            <p className='text-small protocol_text mt-1 mb-2 w-1/2'>
                                                                                {item}
                                                                            </p>
                                                                            <p className='text-small protocol_text mt-1 mb-2 w-1/2'>
                                                                                {blueprint?.blueprint[index + 1]}
                                                                            </p>
                                                                        </div>
                                                                    </>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                </>
                                            )}
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </React.Fragment>
    );
};
