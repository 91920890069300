import React from 'react';

import { useTranslation } from 'react-i18next';

import { percentageChangeType } from '../../types';

interface WebsiteCategoriesProps {
    evaId: string | undefined;
    percentageChange: percentageChangeType;
}

export const WebsiteCategories: React.FC<WebsiteCategoriesProps> = ({ evaId, percentageChange }) => {
    const { t } = useTranslation();
    const previousDayPer = percentageChange?.previousDayDifference;

    return (
        <>
            <div className='engagement_per relative cursor-pointer'>
                {!percentageChange ? (
                    <span className='block no_content'> 0%</span>
                ) : (
                    <div className='content flex justify-center items-center'>
                        <p>
                            <span> {percentageChange?.currentDayPercent}%</span>
                        </p>
                    </div>
                )}
                <div className='engagement_box'>
                    <span
                        className={`per ${
                            previousDayPer < 0 ? 'minus_per' : previousDayPer == 0 ? ' zero_per' : 'plus_per'
                        }`}
                    >
                        <span>{previousDayPer ? previousDayPer : 0}%</span>
                    </span>
                    <p> {t('from_yesterday')} </p>
                </div>
            </div>
        </>
    );
};
