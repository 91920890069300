import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { useAppDispatch } from '../../../../../Redux/store';
import { setSelectedIds } from '../../../../../Redux/TrendMetric';
import { EvaGraphAnalyticsEvaList } from '../../eva/types';
import '../Trends/Trends.css';

const ActiveEvaSelect = ({
    evaId,
    handleEvaChange,
    titleList,
    isShield,
}: {
    evaId: string;
    handleEvaChange: (value: string) => void;
    titleList: EvaGraphAnalyticsEvaList[] | undefined;
    isShield: boolean;
}) => {
    const dispatch = useAppDispatch();
    return (
        <div className='title-box'>
            <FormControl className='select_drop_down'>
                <Select
                    value={evaId}
                    onChange={(e) => {
                        handleEvaChange(e.target.value as string);
                        dispatch(setSelectedIds([]));
                    }}
                >
                    {titleList?.map((item) => {
                        return (
                            <MenuItem
                                className={`${item.isArchived && !item.isPublished && isShield ? 'disable' : ''}`}
                                value={item?.id}
                            >
                                E.V.A {item?.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default ActiveEvaSelect;
