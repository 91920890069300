import i18next from 'i18next';

import CriticalThinking from '../../../../../../src/assets/icons/CriticalThinking.svg';
import CriticalThinkingFill from '../../../../../../src/assets/icons/CriticalThinkingFill.svg';
import Custom from '../../../../../../src/assets/icons/Custom.svg';
import CustomFill from '../../../../../../src/assets/icons/CustomFill.svg';
import GoldStandard from '../../../../../../src/assets/icons/GoldStandard.svg';
import GoldStandardFill from '../../../../../../src/assets/icons/GoldStandardFill.svg';
import GoodPractice from '../../../../../../src/assets/icons/GoodPractice.svg';
import GoodPracticeFill from '../../../../../../src/assets/icons/GoodPracticeFill.svg';
import Policy from '../../../../../../src/assets/icons/Policy.svg';
import PolicyFill from '../../../../../../src/assets/icons/PolicyFill.svg';
import Signal from '../../../../../../src/assets/icons/Signal.svg';
import SignalFill from '../../../../../../src/assets/icons/SignalFill.svg';
import { ProtocolOption, ProtocolTypes } from '../types';

export const OptionData: ProtocolOption[] = [];

i18next.on('initialized', () => {
    OptionData.push(
        {
            name: ProtocolTypes.Critical,
            icon: CriticalThinking,
            linearBg: 'linear-gradient(244.4deg, #F9EFFE 6.11%, #EFEDFF 89.24%)',
            txtColor: 'linear-gradient(244.4deg, #C560F4 6.11%, #5A49FE 89.24%)',
            description: i18next.t('protocolTypesDesc.critical'),
            fillIcon: CriticalThinkingFill,
            lineColor: ' rgba(197, 96, 244, 1)',
            bgColor: '#C560F4',
        },
        {
            name: ProtocolTypes.GoldStandard,
            icon: GoldStandard,
            linearBg: 'linear-gradient(244.4deg, #F9EFFE 6.11%, #EFEDFF 89.24%)',
            txtColor: 'linear-gradient(244.4deg, #C560F4 6.11%, #5A49FE 89.24%)',
            description: i18next.t('protocolTypesDesc.goldStandard'),
            fillIcon: GoldStandardFill,
            lineColor: ' rgba(99, 143, 255, 1)',
            bgColor: '#638FFF',
        },
        {
            name: ProtocolTypes.GoodPractice,
            icon: GoodPractice,
            linearBg: 'linear-gradient(244.4deg, #F9EFFE 6.11%, #EFEDFF 89.24%)',
            txtColor: 'linear-gradient(244.4deg, #C560F4 6.11%, #5A49FE 89.24%)',
            description: i18next.t('protocolTypesDesc.goodPractice'),
            fillIcon: GoodPracticeFill,
            lineColor: 'rgba(90, 73, 254, 1)',
            bgColor: '#5A49FE',
        },
        {
            name: ProtocolTypes.Signal,
            icon: Signal,
            linearBg: 'linear-gradient(244.4deg, rgba(87, 208, 194, 0.1) 6.11%, rgba(9, 139, 148, 0.1) 89.24%)',
            txtColor: 'linear-gradient(244.4deg, #257F8F 7.78%, #56779B 89.24%)',
            description: i18next.t('protocolTypesDesc.signal'),
            fillIcon: SignalFill,
            lineColor: 'rgba(37, 127, 143, 1)',
            bgColor: '#257F8F',
        },
        {
            name: ProtocolTypes.Policy,
            icon: Policy,
            linearBg: 'linear-gradient(244.4deg, rgba(255, 231, 102, 0.1) 6.11%, rgba(255, 179, 102, 0.1) 89.24%)',
            txtColor: 'linear-gradient(0deg, #8B722D, #8B722D)',
            description: i18next.t('protocolTypesDesc.policy'),
            fillIcon: PolicyFill,
            lineColor: 'rgba(255, 179, 102, 1)',
            bgColor: '#FFB366',
        },
        {
            name: ProtocolTypes.Custom,
            icon: Custom,
            linearBg: 'linear-gradient(244.4deg, rgba(108, 109, 184, 0.1) 6.11%, rgba(108, 109, 184, 0.1) 89.24%)',
            txtColor: '  linear-gradient(0deg, rgba(108, 109, 184, 1), rgba(108, 109, 184, 1))',
            description: i18next.t('protocolTypesDesc.custom'),
            fillIcon: CustomFill,
            lineColor: 'rgba(108, 109, 184, 1)',
            bgColor: '#6C6DB8',
        },
    );
});

export const AverageProtocolGraph = '#6fbfe1';

export const protocolTypeIcon = (protocolType: string) => {
    const icon = OptionData.find((item) => item.name === protocolType);
    return icon?.icon;
};
