import { Box, Dialog, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CriticalThinking } from '../../../../src/assets/icons/CriticalThinking.svg';
import { ReactComponent as GoldStandard } from '../../../../src/assets/icons/GoldStandard.svg';
import { ReactComponent as GoodPractice } from '../../../../src/assets/icons/GoodPractice.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/icon-cross-fill.svg';
import { ReactComponent as NorthStarIcon } from '../../../assets/images/north-star-icon.svg';
import { Button } from '../../basic/Button.component';

const NorthStarDialog = ({
    open,
    setClose,
    onConfirm,
}: {
    open: boolean;
    setClose: (value: boolean) => void;
    onConfirm: () => void;
}) => {
    const { t } = useTranslation();
    return (
        <Dialog open={open} onClose={() => setClose(false)} className='dialogPaper'>
            <Box className='bg-white text-black dialogBox'>
                <div className='w-full flex justify-end'>
                    <IconButton edge='end' color='primary' onClick={() => setClose(false)} aria-label='close'>
                        <CloseIcon fill='#8787a1' />
                    </IconButton>
                </div>
                <div className='w-full flex flex-col text-darker text-opacity-75'>
                    <div className='flex flex-col items-center space-x-2'>
                        <div>
                            <NorthStarIcon className='w-10 h-10' />
                        </div>
                        <h1 className='font-semibold mt-2'>North-Star Alignment</h1>
                    </div>
                    <p className='text-sm font-normal italic mt-10'>{t('nsa.dialog.description')}</p>
                    <div className='mt-4 space-y-4'>
                        <p className='font-medium text-sm'>{t('nsa.dialog.this-include')}</p>
                        <div className='text-sm space-y-2 ns-protocolType-container'>
                            <div className='ns-protocolType flex space-x-2 items-center'>
                                <div className='icon'>
                                    <GoldStandard />
                                </div>
                                <p className='text-sm icon-text'>{t('protocolTypes.Gold Standard')}</p>
                            </div>
                            <p className='text-darker text-opacity-50'>{t('nsa.dialog.gs-description')}</p>
                        </div>
                        <div className='text-sm space-y-2 ns-protocolType-container'>
                            <div className='ns-protocolType flex space-x-2 items-center'>
                                <div className='icon'>
                                    <GoodPractice />
                                </div>
                                <p className='text-sm icon-text'>{t('protocolTypes.Good Practice')}</p>
                            </div>
                            <p className='text-darker text-opacity-50'>{t('nsa.dialog.gp-description')}</p>
                        </div>
                        <div className='text-sm space-y-2 ns-protocolType-container'>
                            <div className='ns-protocolType flex space-x-2 items-center'>
                                <div className='icon'>
                                    <CriticalThinking />
                                </div>
                                <p className='text-sm icon-text'>{t('protocolTypes.Critical Thinking')}</p>
                            </div>
                            <p className='text-darker text-opacity-50'>{t('nsa.dialog.ct-description')}</p>
                        </div>
                    </div>
                    <Button
                        className='mt-5'
                        variant='blueRYB'
                        size='rectSmall'
                        onClick={() => {
                            onConfirm();
                            setClose(false);
                        }}
                    >
                        {t('btnNames.confirm')}
                    </Button>
                    <Button className='mt-2' variant='outlinedCancel' size='rectSmall' onClick={() => setClose(false)}>
                        {t('btnNames.cancel')}
                    </Button>
                </div>
            </Box>
        </Dialog>
    );
};

export default NorthStarDialog;
