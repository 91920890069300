import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useTranslation } from 'react-i18next';

import { isValidUrl } from '../../../../../utils/validation';
import { IconAlert } from '../../../../basic/IconAlert.component';
import { Input } from '../../../../basic/Input.component';
import { useStyles } from '../../../../copilot/AccountForm';

const InputNewCompliance = ({
    websites,
    setWebsites,
    removeWebsite,
    error,
    isDuplicateUrls,
    setError,
}: {
    websites: { id: string; url: string }[] | undefined;
    setWebsites: React.Dispatch<
        React.SetStateAction<
            {
                id: string;
                url: string;
            }[]
        >
    >;
    isDuplicateUrls: boolean;
    removeWebsite: (index: number) => void;
    error: string | null;
    setError: (error: string | null) => void;
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleAddWebsite = () => {
        if (websites) {
            const newWebsite = {
                id: '',
                url: '',
            };
            // Update the state by adding the new website object
            setWebsites([...websites, newWebsite]);
        }
    };

    const handleRemoveWebsite = (index: number) => () => {
        removeWebsite(index);
    };

    const handleWebsiteChange = (value: string, index: number) => {
        setWebsites((prevWebsites) =>
            prevWebsites.map((website, idx) => (idx === index ? { ...website, url: value } : website)),
        );
    };

    const handleUserEmailError = (index: number) => {
        if (websites) {
            if (!isValidUrl(websites[index]?.url)) {
                setError('Enter valid website');
                return true;
            }
            setError(null);
        }
    };

    return (
        <div className='w-full '>
            <div className='overflow-y-auto scrollbar max-h-96 w-full'>
                {websites?.map((website, index) => (
                    <div key={index} className='w-full flex items-center space-x-4 mt-2'>
                        <p className='text-xs text-darker text-opacity-75 font-medium'>{t('navi.website')}</p>
                        <div className='mb-2'>
                            <Input
                                placeholder='https://'
                                value={website.url}
                                onChange={(e) => handleWebsiteChange(e, index)}
                                error={handleUserEmailError(index)}
                                className='text-xs'
                            />
                        </div>
                        <button onClick={handleRemoveWebsite(index)}>
                            <CloseOutlinedIcon className={classes.emailCloseIcon} />
                        </button>
                    </div>
                ))}
            </div>
            {error && (
                <div className='my-2'>
                    <IconAlert message={error} className='text-xs' />
                </div>
            )}

            {isDuplicateUrls && (
                <div className='my-2'>
                    <IconAlert message={t('navi.domainAlreadyExists')} className='text-xs' />
                </div>
            )}

            <button
                className='rounded-full w-4 h-4 flex items-center justify-center'
                style={{
                    backgroundColor: 'rgba(15, 16, 68, 0.15)',
                }}
                onClick={handleAddWebsite}
            >
                <p className='text-xs text-darker text-opacity-75'>+</p>
            </button>
        </div>
    );
};

export default InputNewCompliance;
