import clsx from 'clsx';
import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/plain.css';

const InputPhoneCode = ({
    label,
    placeholder,
    onChange,
    value,
}: {
    label: string;
    placeholder: string;
    onChange?: (value: string) => void;
    value: string;
}) => {
    return (
        <div className={clsx('text-sm flex-grow relative w-full')}>
            <div className='flex mb-2 justify-between'>
                <label className='font-medium text-sm'>
                    <span className='text-darker text-opacity-75'>{label}</span>
                </label>
            </div>
            <PhoneInput
                onlyCountries={['us', 'br', 'gb']}
                placeholder={placeholder}
                enableSearch
                containerStyle={{
                    borderRadius: '5px',
                    border: '1px solid #E5E5E5',
                    fontFamily: 'Poppins',
                    width: '100%',
                }}
                inputStyle={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '5px',
                    border: '1px solid #E5E5E5',
                    fontFamily: 'Poppins',
                    fontSize: '12px',
                    color: '#0F1044',
                }}
                dropdownStyle={{
                    borderRadius: '5px',
                    fontSize: '12px',
                    color: '#0F1044',
                    width: '28rem',
                }}
                value={value}
                onChange={(phone) => onChange && onChange(phone)}
            />
        </div>
    );
};

export default InputPhoneCode;
