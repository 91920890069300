import './style.css';
import { useEffect, useState } from 'react';

import { makeStyles, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ReactComponent as MetricsIcon } from '../../../../../../src/assets/icons/icon-metrics.svg';
import { ReactComponent as CloseEye } from '../../../../../assets/icons/icon-purple-eye-closed.svg';
import { ReactComponent as CloseEyeWhite } from '../../../../../assets/icons/icon-white-eye-closed.svg';
import Evaluate from './evaluate/Evaluate.component';
import { clearSvg } from './metrics/MetricCard.component';
import Metrics from './metrics/Metrics.component';

const useStyles = makeStyles((theme) => ({
    customTooltip: (props: { currentSlide: number }) => ({
        backgroundColor: '#0014FF',
        fontFamily: 'Poppins',
        color: 'white',
        fontSize: '10px',
        fontWeight: 500,
        visibility: props.currentSlide === 0 ? 'hidden' : 'visible',
    }),
}));

const RightSidePanel = ({
    setEvalMode,
    setMetricsMode,
    evalMode,
    metricsMode,
    evaId,
    protocol,
    protocolName,
    setEvaluateHint,
    setActionType,
    setNewProtocol,
    setOrbitLoader,
    showNotification = false,
    setEditUserResponse,
    handleDlClick,
    setMarkerIndex,
    markerIndex,
    protocolUpdated,
    currentSlide,
    genMetricProtocol,
    setMetricLoading,
}: {
    setEvalMode: (mode: boolean) => void;
    setMetricsMode: (mode: boolean) => void;
    evalMode: boolean;
    metricsMode: boolean;
    evaId?: string;
    protocol: { nuance: string; threshold: number }[];
    protocolName: string;
    setEvaluateHint: (hint: string | null) => void;
    setActionType: (type: string | null) => void;
    setNewProtocol: React.Dispatch<
        React.SetStateAction<{
            nuance: string;
            threshold: number;
            oldNuance?: string | undefined;
        }>
    >;
    setOrbitLoader: React.Dispatch<React.SetStateAction<boolean>>;
    showNotification?: boolean;
    setEditUserResponse: React.Dispatch<React.SetStateAction<boolean>>;
    handleDlClick: (event: React.MouseEvent<HTMLElement>, index?: number | null) => void;
    setMarkerIndex: (value: number | null) => void;
    markerIndex: number | null;
    protocolUpdated: boolean;
    currentSlide: number;
    genMetricProtocol: (mode: string, title: string, inputValues: { nuance: string; threshold: number }[]) => void;
    setMetricLoading: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
    const classes = useStyles({ currentSlide });
    const { t } = useTranslation();
    const [showCompletePanel, setShowCompletePanel] = useState(false);

    useEffect(() => {
        if (evalMode || metricsMode) setShowCompletePanel(true);
        else {
            setShowCompletePanel(false);
            clearSvg();
        }
    }, [evalMode, metricsMode]);
    const handleModeChange = (mode: 'activity' | 'evaluate' | 'metrics') => {
        switch (mode) {
            case 'evaluate':
                setEvalMode(!evalMode);
                setMetricsMode(false);
                clearSvg();
                break;
            case 'metrics':
                setMetricsMode(!metricsMode);
                setEvalMode(false);
                break;
            default:
                break;
        }
    };
    return (
        <div className='right-side-panel-outside-container h-full no-scrollbar'>
            <div className='h-full flex'>
                {showCompletePanel && (
                    <div
                        className={clsx(
                            'rsp-expanded-container no-scrollbar',
                            evalMode ? 'rps-eval-background' : 'rps-default-background',
                        )}
                    >
                        {evalMode && (
                            <Evaluate
                                evaId={evaId}
                                protocol={protocol}
                                protocolName={protocolName}
                                setEvaluateHint={setEvaluateHint}
                                setActionType={setActionType}
                                setOrbitLoader={setOrbitLoader}
                                setNewProtocol={setNewProtocol}
                                setEditUserResponse={setEditUserResponse}
                            />
                        )}
                        {metricsMode && (
                            <Metrics
                                evaId={evaId}
                                handleDlClick={handleDlClick}
                                setMarkerIndex={setMarkerIndex}
                                markerIndex={markerIndex}
                                protocolUpdated={protocolUpdated}
                                genMetricProtocol={genMetricProtocol}
                                setMetricLoading={setMetricLoading}
                            />
                        )}
                    </div>
                )}
                {showCompletePanel && <div className='rsp-veritical-line' />}
                <div className={clsx('rsp-menu', showCompletePanel ? 'rsp-menu-expanded' : 'rsp-menu-no-expand')}>
                    <Tooltip
                        title={t('evaluate')}
                        classes={{
                            tooltip: classes.customTooltip,
                        }}
                    >
                        <div
                            className={clsx('rsp-icon', currentSlide === 1 && 'cursor-pointer')}
                            onClick={() => handleModeChange('evaluate')}
                        >
                            {evalMode ? (
                                <div className='eva-test-eye-icon-active'>
                                    <CloseEyeWhite width={15} height={15} fill='rgba(15, 16, 68, 0.75)' />
                                </div>
                            ) : (
                                <CloseEye width={15} height={15} fill='#6FBFE1' />
                            )}
                        </div>
                    </Tooltip>
                    <Tooltip
                        title={t('metrics')}
                        hidden
                        classes={{
                            tooltip: classes.customTooltip,
                        }}
                    >
                        <div
                            className={clsx(
                                'rsp-icon',
                                currentSlide === 1 && 'cursor-pointer',
                                metricsMode && 'rsp-icon-selected',
                            )}
                            onClick={() => handleModeChange('metrics')}
                        >
                            {showNotification && <div className='metric-notification' />}
                            <MetricsIcon
                                className='w-3 h-3'
                                fill={metricsMode ? '#22325D' : '#6FBFE1'}
                                fillOpacity={metricsMode ? '0.75' : '1'}
                            />
                        </div>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

export default RightSidePanel;
