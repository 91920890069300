import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import { ApexOptions } from 'apexcharts';
import moment from 'moment';
import Chart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import 'moment/locale/pt-br';

import i18n from '../../../../../i18n';
import { RootState, useAppDispatch } from '../../../../../Redux/store';
import { setSelectedGraphData } from '../../../../../Redux/TrendMetric';
import { AverageProtocolGraph, OptionData } from '../../eva/components/ProtocolTypeList';
import { GraphProtocol } from '../../eva/types';

export interface ProtocolTrendChartProps {
    selectedProtocolsList: GraphProtocol[];
    timeFilter: string;
    filterFormat: string[];
    averageProtocolTrigger: number[];
    setTimeFilter: Dispatch<SetStateAction<string>>;
    setSelectedTrendProtocol: Dispatch<SetStateAction<string[]>>;
    setSelectPreviousProtocol: Dispatch<SetStateAction<string[]>>;
    setBackTimeFilter: Dispatch<SetStateAction<string[]>>;
}

const ProtocolTrendChart: React.FC<ProtocolTrendChartProps> = ({
    timeFilter,
    filterFormat,
    selectedProtocolsList,
    averageProtocolTrigger,
    setTimeFilter,
    setSelectedTrendProtocol,
    setSelectPreviousProtocol,
    setBackTimeFilter,
}) => {
    const { t } = useTranslation();

    // Transform selectedProtocolsList to series format
    const series = selectedProtocolsList?.map((protocol) => {
        const protocolData = protocol.data.length > 0 ? protocol.data : Array(filterFormat?.length).fill(0);
        return {
            name: protocol.protocolName,
            data: protocolData,
        };
    });

    const dispatch = useAppDispatch();

    const getGraphData = () => {
        if (timeFilter === 'All') {
            const firstFormat = filterFormat[0];
            if (moment(firstFormat, 'MM-YYYY', true).isValid()) {
                setTimeFilter('Month');
            } else if (moment(firstFormat, 'YYYY', true).isValid()) {
                setTimeFilter('Year');
            }
        }
        if (timeFilter === 'Year') {
            setTimeFilter('Month');
        }

        if (timeFilter == 'Month' || timeFilter == 'Week') {
            setTimeFilter('Day');
        }
        const { value, protocol, timeData } = tooltipDataRef.current;
        if (protocol) {
            setSelectedTrendProtocol([protocol]);
            setSelectPreviousProtocol([protocol]);
        }
        setBackTimeFilter((prevState) => {
            // Create a unique array of filters, including the new timeFilter
            const updatedState = Array.from(new Set([...prevState, timeFilter])); // Convert Set to array
            return updatedState;
        });

        dispatch(
            // Dispatch the action
            setSelectedGraphData({
                value: value,
                drilledDate: timeData,
            }),
        );
    };

    //to used for selected metric chart
    const isSelectMetric = useSelector((state: RootState) => state.TrendMetric.selectedIds);

    const emptySeries = [
        {
            name: '',
            data: averageProtocolTrigger,
        },
    ];
    let timeFormat = '';
    let categories: number[] | string[] = [];

    const currentYear = new Date().getFullYear();

    switch (timeFilter) {
        case 'Year':
        case 'All':
            timeFormat = 'MMM YYYY';
            categories = filterFormat?.map((date) => {
                if (date.includes('-')) {
                    // If the format is MM-YYYY
                    const [month, year] = date.split('-');

                    return moment(`${year}-${month}-01`, 'YYYY-MM-DD')
                        .locale(i18n.language || 'en')
                        .format(timeFormat);
                } else {
                    // If the format is only YYYY
                    return moment(`${date}-01-01`, 'YYYY-MM-DD')
                        .locale(i18n.language || 'en')
                        .format('YYYY');
                }
            });
            break;
        case 'Month':
            timeFormat = 'D'; // Day of the month
            categories = filterFormat?.map((date) => {
                const [day, month, year] = date.split('-');
                return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD')
                    .locale(i18n.language || 'en')
                    .format(timeFormat);
            });
            break;
        case 'Week':
            timeFormat = 'DD MMM';
            categories = filterFormat?.map((date) => {
                const [day, month] = date.split('-');
                return moment(`${currentYear}-${month}-${day}`, 'YYYY-MM-DD')
                    .locale(i18n.language || 'en')
                    .format(timeFormat);
            });
            break;
        case 'Day':
            timeFormat = 'HH:mm';
            categories = filterFormat;

            break;
        default:
            categories = [];
            break;
    }

    const allData = selectedProtocolsList.flatMap((protocol) => protocol.data);
    const averageVal =
        selectedProtocolsList && selectedProtocolsList.length > 0
            ? allData[allData.length - 1]
            : averageProtocolTrigger && averageProtocolTrigger.length > 0
            ? Math.max(...averageProtocolTrigger)
            : 0;
    // Ensure there are at least two values to compare
    // Sort the array in ascending order
    allData.sort((a, b) => a - b);
    // Calculate the range of the data
    const minValue = allData[0] ?? 0;
    const maxValue =
        selectedProtocolsList.length > 0
            ? allData[allData.length - 1] < 3
                ? 3
                : allData[allData.length - 1]
            : averageVal < 3
            ? 3
            : averageVal;
    const dataRange = maxValue - minValue;
    const maxTicks = 10;
    const stepSize = dataRange / maxTicks;
    const stepSizes = [0.0, 0, 1, 2, 5, 10, 20, 50, 100, 200, 500, 1000, 2000];
    const suitableStepSize = stepSizes.find((size) => stepSize <= size) || stepSizes[stepSizes.length - 1];
    // Calculate the new maximum value for the y-axis
    const maxYValue = Math.ceil(maxValue / suitableStepSize) * suitableStepSize + suitableStepSize;

    const tooltipDataRef = useRef<{ value: number | null; protocol: string | null; timeData: string }>({
        value: null,
        timeData: '',
        protocol: null,
    });

    const options: ApexOptions = {
        chart: {
            type: 'area',
            height: 350,
            width: '100%',
            toolbar: {
                show: false,
            },
            stacked: false,
            zoom: {
                enabled: false,
            },
            brush: {
                enabled: false,
            },
        },

        colors:
            selectedProtocolsList.length > 0
                ? selectedProtocolsList.map((item) => OptionData.find((ob) => ob.name === item.type)?.bgColor)
                : [AverageProtocolGraph],
        dataLabels: {
            enabled: false,
        },

        markers: {
            size: 0, // Size of the points
            colors: ['rgba(0, 20, 255, 0.75)'], // Color of the points
            strokeWidth: 0, // Border width
            hover: {
                size: 3, // Size of the points on hover
            },
            shape: 'circle', // Shape of the points (circle, square, etc.)
        },

        stroke: {
            curve: 'smooth',
            width: 1,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.3,
                opacityTo: 0.9,
                stops: [0, 90, 100],
            },
        },
        xaxis: {
            type: timeFilter === 'Day' ? 'category' : 'datetime',
            categories: categories,
            labels: {
                format: timeFormat,
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
            },
            position: 'bottom',
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
                offsetX: -3,
                offsetY: -3,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontFamily: 'Poppins',
                    fontWeight: '400',
                },
                offsetX: 0,
                offsetY: -1,
                formatter: function (value: number): string {
                    return Math.round(value).toString();
                },
            },
            max: maxYValue,
            min: 0,
            axisBorder: {
                show: true,
                color: '#0f104412',
                offsetX: -3,
                offsetY: -2,
            },
        },
        tooltip: {
            followCursor: true,
            enabled: true,
            onDatasetHover: {
                highlightDataSeries: true,
            },
            inverseOrder: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const value: number = series[seriesIndex][dataPointIndex];
                const timeData = filterFormat[dataPointIndex]; // This can be the date or current category data
                const baseData = selectedProtocolsList.length > 0 && selectedProtocolsList[seriesIndex].data;
                let filteredProtocols: GraphProtocol[] = [];

                // Create filledBaseData based on time filter
                const filledBaseData = (() => {
                    if (baseData && baseData.length > 0) {
                        return baseData;
                    }

                    switch (timeFilter) {
                        case 'Week':
                            return new Array(7).fill(0);
                        case 'Month':
                            return new Array(30).fill(0);
                        case 'Year':
                            return new Array(13).fill(0);
                        case 'All':
                            return new Array(12).fill(0);
                        default:
                            return [];
                    }
                })();

                if (filledBaseData) {
                    filteredProtocols = selectedProtocolsList.filter((protocol) => {
                        const protocolValue =
                            protocol.data[dataPointIndex] !== undefined ? protocol.data[dataPointIndex] : 0;
                        const baseDataValue =
                            filledBaseData[dataPointIndex] !== undefined ? filledBaseData[dataPointIndex] : 0;
                        return protocolValue === value && baseDataValue === value;
                    });
                }

                if (filteredProtocols.length > 0) {
                    tooltipDataRef.current = {
                        value,
                        timeData,
                        protocol: filteredProtocols[0].protocolName, // Set the first matched protocolName
                    };
                } else {
                    tooltipDataRef.current = { value, timeData, protocol: '' }; // Reset if no protocols match
                }

                // Create the tooltip content for filtered protocols
                const filteredProtocolsHTML = filteredProtocols
                    .map((protocol, index, arr) => {
                        const iconSrc = OptionData.find((obj) => obj.name === protocol.type)?.icon;

                        return `
                          <div style="display: flex; gap: 5px; ${
                              index === arr.length - 1 ? 'padding-bottom: 20px' : ''
                          }">
                            <div>
                                ${
                                    iconSrc
                                        ? `<img src="${iconSrc}" alt="${protocol.type} Icon" style="width: 20px; height: 20px;"/>`
                                        : ''
                                }
                            </div>
                            <div style="font-weight: 500; font-size: 11px; overflow: hidden; width: 150px; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical;">
                                ${protocol.protocolName}
                            </div>
                        </div>
                      `;
                    })
                    .join(''); // Joining the HTML string for each filtered protocol

                const timeDataHtml = timeFilter == 'Day' ? '' : timeData;

                // Return the full tooltip HTML including the value and time category
                return `  
                    <div class="trend_graph_tooltip" style=" position: relative;  padding:5px; color: #fff; background-color: rgba(0, 20, 255, 0.75); border-radius: 7px; width: ${
                        selectedProtocolsList?.length > 0 ? '214px' : timeFilter == 'Day' ? '50px' : '100px'
                    }; height: auto;" >
                       
                    <div class=" ${
                        selectedProtocolsList?.length > 0 ? 'metric_scroll_tooltips margin-bottom : 5px' : ''
                    }  " style="  width : 100%; position: relative; overflow-y: auto; ">
                            ${filteredProtocolsHTML}
                        </div>

                            <div style="font-size: 10px; line-height: 15px; display : flex; justify-content: ${
                                selectedProtocolsList?.length > 0 || timeFilter != 'Day' ? ' space-between' : '  center'
                            }; align-items: center; position : relative;">
                                <div > 
                                    ${timeDataHtml}
                                </div>
                            
                                <div">
                                    ${value}
                                </div>

                            <div>
                    </div>
                `;
            },
            shared: true,
            intersect: false,
        },

        legend: {
            show: false,
        },
        grid: {
            show: true,
            borderColor: '#0f104412',
            strokeDashArray: 0,
            padding: {
                left: 10,
                right: 30,
            },
        },
    };

    return (
        <div
            id='chart'
            onClick={() => {
                if (timeFilter !== 'Day') {
                    getGraphData();
                }
            }}
        >
            <Chart
                options={options}
                series={selectedProtocolsList.length > 0 ? series : emptySeries}
                type='area'
                height={isSelectMetric.length > 0 ? 250 : 430}
            />
            <h5 className='chart_title'>{t('protocols_triggers') }</h5>
        </div>
    );
};

export default ProtocolTrendChart;
