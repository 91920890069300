import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { PaymentMethod } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { usePaymentMethods } from '../../../hooks/usePaymentMethods';
import { Button } from '../../basic/Button.component';
import { Checkbox } from '../../basic/Checkbox.component';
import { IconAlert } from '../../basic/IconAlert.component';
import { Input } from '../../basic/Input.component';

const CreditCardDetails = ({
    currentCreditCard,
    isCardTypeChecked,
}: {
    currentCreditCard?: PaymentMethod;
    isAppointment?: boolean;
    amount?: number;
    isCardTypeChecked?: boolean;
}) => {
    const {
        fields,
        error,
        setError,
        createPaymentMethod,
        defaultMethod,
        setDefaultMethod,
        updatePaymentMethod,
        loading,
        createOrganisationPaymentMethod,
        updateOrganisationPaymentMethod,
        handleStripeElementChange,
        validStripeElements,
    } = usePaymentMethods();

    const { id: organisationId } = useParams<{ id: string }>();
    const { cardId } = useParams<{ cardId: string }>();
    const { t } = useTranslation();

    const handleSubmit = async () => {
        if (!isCardTypeChecked) {
            setError(t('validation.cardType'));
            return;
        }
        if (organisationId) return handleOrganisationSubmit();
        else {
            if (currentCreditCard) updatePaymentMethod(currentCreditCard.id);
            else await createPaymentMethod();
        }
    };

    const handleOrganisationSubmit = async () => {
        if (cardId) await updateOrganisationPaymentMethod(cardId, organisationId);
        else await createOrganisationPaymentMethod(organisationId);
    };

    return (
        <div className='text-sm text-darker text-opacity-75 font-medium'>
            <Input
                label={t('creditCard.name')}
                placeholder={
                    currentCreditCard ? (currentCreditCard.billing_details.name as string) : t('creditCard.name')
                }
                type='text'
                {...fields.name}
                error={error && fields.name.error ? true : false}
            />
            <div className='grid lg:grid-cols-5 gap-x-10 mt-6'>
                <div className='lg:col-span-3'>
                    <p className='mb-2'>{t('creditCard.cardNumber')}</p>
                    <div className=''>
                        <CardNumberElement
                            options={{
                                classes: {
                                    base: 'StripeInput--base',
                                },
                                style: {
                                    base: {
                                        '::placeholder': {
                                            color: '#0F104480',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins, sans-serif',
                                        },
                                        fontSize: '12px',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#0F1044BF',
                                    },
                                },
                                placeholder: currentCreditCard
                                    ? `**** **** **** ${currentCreditCard.card?.last4}`
                                    : t('creditCard.cardNumber'),
                            }}
                            onChange={(e) => handleStripeElementChange(e, 'card')}
                        />
                    </div>
                </div>
                <div>
                    <p className='mb-2'>{t('creditCard.expiration')}</p>
                    <div className=''>
                        <CardExpiryElement
                            options={{
                                classes: {
                                    base: 'StripeInput--base',
                                },
                                style: {
                                    base: {
                                        '::placeholder': {
                                            color: '#0F104480',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins, sans-serif',
                                        },
                                        fontSize: '12px',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#0F1044BF',
                                    },
                                },
                                placeholder: currentCreditCard
                                    ? `${currentCreditCard.card?.exp_month}/${currentCreditCard.card?.exp_year
                                          .toString()
                                          .slice(-2)}`
                                    : 'MM/YY',
                            }}
                            onChange={(e) => handleStripeElementChange(e, 'expiration')}
                        />
                    </div>
                </div>
                <div>
                    <p className='mb-2'>CVV</p>
                    <div className=''>
                        <CardCvcElement
                            options={{
                                classes: {
                                    base: 'StripeInput--base',
                                },
                                style: {
                                    base: {
                                        '::placeholder': {
                                            color: '#0F104480',
                                            fontSize: '12px',
                                            fontFamily: 'Poppins, sans-serif',
                                        },
                                        fontSize: '12px',
                                        fontFamily: 'Poppins, sans-serif',
                                        color: '#0F1044BF',
                                    },
                                },
                                placeholder: 'CVV',
                            }}
                            onChange={(e) => handleStripeElementChange(e, 'cvv')}
                        />
                    </div>
                </div>
            </div>
            <div className='flex items-center mt-4 space-x-2'>
                <Checkbox checked={defaultMethod} onClick={() => setDefaultMethod(!defaultMethod)} />
                <p className='text-normal'>{t('creditCard.default')}</p>
            </div>
            <p className='my-4 text-xs text-darker text-opacity-50'>
                {t('creditCard.message')}
                <br />
                <br />
                {t('creditCard.terms', { keyword: `"Saved"` })}{' '}
                <a className='underline' href='https://blankstate.ai/terms-conditions/' target='_blank'>
                    {t('creditCard.termsLink')}
                </a>
                ,{' '}
                <a className='underline' href='https://blankstate.ai/privacy-policy/' target='_blank'>
                    {t('footer.privacy')}
                </a>{' '}
                {t('creditCard.cancellation')}{' '}
            </p>
            {error && <IconAlert type='error' message={error} className='mb-4' />}
            <div className='flex flex-row space-x-12 items-center justify-center w-full'>
                <Button
                    disabled={loading || !validStripeElements}
                    onClick={handleSubmit}
                    variant='bberryBlue'
                    size='oval'
                    className={'w-full'}
                >
                    {t('btnNames.confirm')}
                </Button>
            </div>
        </div>
    );
};

export default CreditCardDetails;
