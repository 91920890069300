import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { appInsights } from '../../../../../AppInsights';
import { DomainTLD } from '../../../../../store/OrganisationStore';
import HorizontalSeparator from '../../../../basic/HorizontalSeparator';
import { Loading } from '../../../../loading';
import { useServices } from '../../../../ServiceProvider';

const TopDomain = () => {
    const { t } = useTranslation();
    const { organisation } = useServices();
    const { id } = useParams<{ id: string }>();

    const { data: tldData, isLoading } = useQuery<DomainTLD[] | null>(['domain-TLD', id], async () => {
        try {
            return await organisation.getAllDomainByTLD(id);
        } catch (e) {
            console.error(e);
            if (e instanceof Error) {
                appInsights.trackException({ error: e });
            }
        }
    });

    return (
        <div className='w-full bg-white p-4 rounded-xl border scrollbar top_domain   border-opacity-10 border-blue-dark text-darker text-opacity-75'>
            <div>
                <h1 className='novi_left_heading'>{t('navi.topLevelDomains')}</h1>
            </div>
            <div>
                <div className='grid grid-cols-2 gap-6 mt-5 text-xs font-medium'>
                    <p>{t('navi.tldZone')}</p>
                    <p>{t('navi.domains')}</p>
                </div>
                <HorizontalSeparator className='bg-primary-darker bg-opacity-25 mt-2' />
            </div>
            {isLoading ? (
                <div className='w-full flex justify-center mt-2'>
                    <CircularProgress variant='indeterminate' />
                </div>
            ) : (
                tldData &&
                tldData.map((data) => (
                    <div>
                        <div className='topDomainTable text-xs mt-2'>
                            <div className='flex space-x-4 items-center col-span-2'>
                                <p className='font-medium w-20'>.{data.tld}</p>
                            </div>
                            <div className='col-span-1 ml-2 font-normal'>{data.percentage}%</div>
                            <div className='domainLabel'>{data.count}</div>
                        </div>
                        <HorizontalSeparator className='bg-primary-darker bg-opacity-10 mt-2' />
                    </div>
                ))
            )}
        </div>
    );
};

export default TopDomain;
