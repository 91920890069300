import { useState } from 'react';

import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

interface HeatmapData {
    x: string;
    y: number;
}

export type ScatterData = {
    name: string;
    x: string;
    y: number;
};

interface Series {
    name: string;
    data: HeatmapData[];
}

const HeatmapChart = ({ scatterPlotData }: { scatterPlotData: ScatterData[] }) => {
    const { t } = useTranslation();
    const sampleData: Series[] = [
        {
            name: t('risk.heatmap.insignificant'),
            data: [
                { x: 'Very Low', y: 100 },
                { x: 'Low', y: 100 },
                { x: 'Moderate', y: 100 },
                { x: 'High', y: 80 },
                { x: 'Very High', y: 20 },
            ],
        },
        {
            name: t('risk.heatmap.minor'),
            data: [
                { x: 'Very Low', y: 100 },
                { x: 'Low', y: 100 },
                { x: 'Moderate', y: 80 },
                { x: 'High', y: 20 },
                { x: 'Very High', y: 40 },
            ],
        },
        {
            name: t('risk.heatmap.moderate'),
            data: [
                { x: 'Very Low', y: 100 },
                { x: 'Low', y: 80 },
                { x: 'Moderate', y: 20 },
                { x: 'High', y: 40 },
                { x: 'Very High', y: 60 },
            ],
        },
        {
            name: t('risk.heatmap.major'),
            data: [
                { x: 'Very Low', y: 80 },
                { x: 'Low', y: 20 },
                { x: 'Moderate', y: 40 },
                { x: 'High', y: 60 },
                { x: 'Very High', y: 60 },
            ],
        },
        {
            name: t('risk.heatmap.critical'),
            data: [
                { x: 'Very Low', y: 20 },
                { x: 'Low', y: 26 },
                { x: 'Moderate', y: 60 },
                { x: 'High', y: 60 },
                { x: 'Very High', y: 60 },
            ],
        },
    ];

    const [hoveredPoint, setHoveredPoint] = useState<{
        x: string;
        y: number;
        name: string;
        top: number;
        left: number;
    } | null>(null);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMouseEnter = (dataPoint: ScatterData, event: any) => {
        const { clientX: x, clientY: y } = event;
        setHoveredPoint({ x: dataPoint.x, y: dataPoint.y, name: dataPoint.name, top: y - 5, left: x });
    };

    const getAnnotations = () => {
        const annotations: {
            x: string;
            y: number;
            marker: {
                size: number;
                fillColor: string;
                strokeColor: string;
                strokeWidth: number;
                shape: string;
                offsetX?: number;
                offsetY?: number;
            };
            label?: {
                borderColor: string;
                offsetX: number;
                offsetY: number;
                style: { color: string; background: string };
                text: string;
            };
            seriesIndex?: number;
            mouseEnter?: () => void;
            mouseLeave?: () => void;
        }[] = [];

        scatterPlotData.forEach((s, seriesIndex) => {
            if (s.y > 0) {
                annotations.push({
                    x: s.x,
                    y: s.y,
                    marker: {
                        size: 5,
                        fillColor: 'rgba(15, 16, 68, 0.75)',
                        strokeColor: '#fff',
                        strokeWidth: 1,
                        shape: 'circle',
                    },
                    seriesIndex,
                    mouseEnter: () => handleMouseEnter(s, event),
                });
            }
        });

        return annotations;
    };

    const options: ApexOptions = {
        chart: {
            type: 'heatmap',
            height: 350,
            toolbar: {
                show: false,
            },
        },
        plotOptions: {
            heatmap: {
                shadeIntensity: 0,
                colorScale: {
                    ranges: [
                        { from: 0, to: 20, name: 'low', color: '#1F968B' },
                        { from: 21, to: 40, name: 'medium', color: '#32648E' },
                        { from: 41, to: 60, name: 'high', color: '#440D54' },
                        { from: 61, to: 80, name: 'extreme', color: '#75D055' },
                        { from: 81, to: 100, name: 'critical', color: '#FDE725' },
                    ],
                },
                distributed: false,
                radius: 5,
            },
        },
        dataLabels: {
            enabled: false, // Disable data labels if you only want tooltips
        },
        legend: {
            show: false,
        },
        xaxis: {
            type: 'category',
            categories: [
                t('risk.heatmap.veryLow'),
                t('risk.heatmap.low'),
                t('risk.heatmap.moderate'),
                t('risk.heatmap.high'),
                t('risk.heatmap.veryHigh'),
            ],
            title: {
                text: t('risk.heatmap.breachSeverityIndex'),
                style: {
                    color: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '11px',
                    fontWeight: '600',
                    fontFamily: 'Poppins',
                },
            },
            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '400',
                    fontFamily: 'Poppins',
                },
            },
            axisBorder: {
                show: false,
                color: '#000',
            },
            axisTicks: {
                show: false,
                color: '#000',
            },
        },
        yaxis: {
            title: {
                text: t('risk.heatmap.impact'),
                style: {
                    color: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '11px',
                    fontWeight: '600',
                    fontFamily: 'Poppins',
                },
            },
            labels: {
                style: {
                    colors: 'rgba(15, 16, 68, 0.75)',
                    fontSize: '10px',
                    fontWeight: '400',
                    fontFamily: 'Poppins',
                },
            },
            axisBorder: {
                show: false,
                color: '#000',
            },
            axisTicks: {
                show: false,
                color: '#000',
            },
        },
        tooltip: {
            enabled: false, // Disable default tooltips
        },
        annotations: {
            points: getAnnotations(),
        },
        states: {
            hover: {
                filter: {
                    type: 'none', // Disable the filter on hover
                },
            },
        },
    };

    return (
        <div className='relative w-full heatmap' onMouseOver={() => setHoveredPoint(null)}>
            <ReactApexChart id='mysvg' options={options} series={sampleData} type='heatmap' height={400} />
            {hoveredPoint && (
                <div
                    className='hitmaptooltip flex items-center'
                    style={{
                        fontSize: '10px',
                        position: 'fixed',
                        top: hoveredPoint.top,
                        left: hoveredPoint.left,
                        background: '#0014FF',
                        color: '#fff',
                        padding: '8px',
                        borderRadius: '7px',
                        pointerEvents: 'none', // Ensure tooltip doesn't block mouse events
                        transform: 'translate(-50%, -100%)', // Adjust the transform to position the tooltip correctly
                    }}
                >
                    {hoveredPoint.name}
                </div>
            )}
        </div>
    );
};

export default HeatmapChart;
